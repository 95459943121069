import React, { useState } from 'react'
import { 
  colors, 
  Box, 
  Container
} from '@mui/material';
import { Theme } from '@mui/material/styles'
import NavBar from './NavBar';
import SideDrawer from './SideDrawer';
import { styleConstants } from '../../services/Constants';
import { useLocation } from 'react-router-dom';
import SimpleBackdrop from '../common/SimpleBackdrop';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = (props: LayoutProps) => {

  const [sideDrawerOpen, setSideDrawerOpen] = useState(false)
  const location = useLocation()

  const handleClose = () => {
    setSideDrawerOpen(false)
  }

  const handleOpen = () => {
    setSideDrawerOpen(true)
  }
  
  return ( 
    <Box 
      id={"header"}
      component="main"
      sx={{ 
        display: 'flex',
      }} 
    >
      <NavBar
        open={sideDrawerOpen}
        handleOpen={handleOpen}
      />

      <SideDrawer
        open={sideDrawerOpen}
        handleClose={handleClose}
      />

        <Box sx={(theme: Theme) => ({
          flexGrow: 1,
          padding: theme.spacing(3),
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          marginLeft: `-${styleConstants.drawerWidth}px`,
          background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(34,144,194,1) 35%, rgba(6,159,190,1) 100%);', 
          width: '100%',
          minHeight: '100vh',
          backgroundImage: location.pathname === '/' ? `url(${process.env.PUBLIC_URL + '/assets/bg.jpg'})` : '',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          // height: window.innerHeight,
          p: 3,
          ...(sideDrawerOpen && 
            {
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
          }),
        })}>
        <Container maxWidth='xl'>
          <SimpleBackdrop/>
          <Box sx={ (theme: Theme) => ({
            ...theme.mixins.toolbar
            })}
          />
          {props.children}
        </Container>
      </Box>

    </Box>
  );
}
 
export default Layout;