import SubjectOutlined from "@mui/icons-material/SubjectOutlined";
import FolderIcon from '@mui/icons-material/Folder';
import { 
  Drawer,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  IconButton,
  Divider,
  colors,
} from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import BiotechIcon from '@mui/icons-material/Biotech';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate, useLocation } from 'react-router-dom'
import { styleConstants } from "../../services/Constants";
import theme from "../../theme";
import { useAuth } from "../../services/custom_hooks/useAuth";
 
interface SideDrawerProps {
  open: boolean
  handleClose: () => void
}

const SideDrawer = (props: SideDrawerProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const auth     = useAuth()

  const menuAuthItems = [
    {
      text: 'Proyectos',
      icon: <FolderIcon color="secondary"/>,
      path:"/proyectos"
    },
    {
      text: 'Mis Medidores',
      icon: <BiotechIcon color="secondary"/>,
      path:"/measurer"
    },
  ]

  const menuItems = [
    {
      text: 'Home',
      icon: <SubjectOutlined color="secondary"/>,
      path:"/"
    },
    {
      text: 'Aprendizaje',
      icon: <LocalLibraryIcon color="secondary"/>,
      path:"/aprendizaje"
    },
    {
      text: 'About',
      icon: <InfoIcon color="secondary"/>,
      path:"/about"
    },
  ]

  return ( 
    <Drawer
      sx={{ 
        width: styleConstants.drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: styleConstants.drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: '#020024',//'#252525'
        },
      }}
      variant="persistent"
      anchor="left"
      open={props.open}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        mb: 2,
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
      }}>

        <IconButton onClick={props.handleClose} sx={{ color: 'white' }}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>

      </Box>

      {auth && 
      <Box>
        <Divider textAlign="left" sx={{ color: 'white' }}>USUARIO</Divider>

        <List>
          {menuAuthItems.map(item => (
            <ListItem
              button
              key={item.text}
              onClick={() => navigate(item.path)}
              sx={{
                backgroundColor: location.pathname === item.path ? colors.grey[400] : ''
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText sx={{color:'white'}} primary={item.text}/>
            </ListItem>
          ))}
        </List>

        
      </Box>
      }
      <Divider textAlign="left" sx={{ color: 'white' }}>VARIOS</Divider>

      <List>
        {menuItems.map(item => (
          <ListItem
            button
            key={item.text}
            onClick={() => navigate(item.path)}
            sx={{
              backgroundColor: location.pathname === item.path ? colors.grey[400] : ''
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText sx={{color:'white'}} primary={item.text}/>
          </ListItem>
        ))}
      </List>

    </Drawer>    
  );
}
 
export default SideDrawer;