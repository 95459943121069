import React from "react";
import { BrowserRouter as Router, Navigate, Route, Routes,  } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import Dashboard from "./pages/Dashboard";
import { ProtectedRoute } from "./components/auth/ProtectedRoute";
import ProjectList from "./pages/ProjectsPage";
import StartPage from "./pages/StartPage";
import MeasurerPage from "./pages/MeasurerPage";
import ProfilePage from "./pages/ProfilePage";
import ChangePasswordForm from "./components/auth/ChangePasswordForm";
import Layout from "./components/layout/Layout";
import RegisterPage from "./pages/RegisterPage";
import LoginPage from "./pages/LoginPage";
import AboutPage from "./pages/AboutPage";

const App = () => {
  return (
    <Router>
      <Layout>
        <CssBaseline/>
        <Routes>
          <Route
            path="/login"
            element={<LoginPage/>}
          />

          <Route
            path="/register"
            element={<RegisterPage/>}
          />

          <Route
            path="/"
            element={<StartPage/>}
          />

          <Route
            path="/about"
            element={<AboutPage/>}
          />

          <Route
            path="/proyectos"
            element={
              <ProtectedRoute>
                <ProjectList/>
              </ProtectedRoute>
            }
          />

          <Route
            path="/dashboard/:user_id"
            element={
              <ProtectedRoute>
                <Dashboard/>
              </ProtectedRoute>
            }
          />

          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <ProfilePage/>
              </ProtectedRoute>
            }
          />

          <Route
            path="/measurer"
            element={
              <ProtectedRoute>
                <MeasurerPage/>
              </ProtectedRoute>
            }
          />

          <Route
            path="/change_password"
            element={
              // <ProtectedRoute>
                <ChangePasswordForm/>
              // {/* </ProtectedRoute> */}
            }
          />

          <Route
              path="*"
              element={<Navigate to="/" />}
          />
        </Routes>
        </Layout>
    </Router>
  );
};
export default App;
