import createAxios from "./HttpService";

const getAll = (project_id: number, item_id: number) => {
  const http = createAxios()
  return http.get(`/projects/${project_id}/dataset/${item_id}/measurements`);
};

const get = (project_id: number, item_id: number, files) => {
  const http = createAxios()
  return http.get(`/projects/${project_id}/dataset/${item_id}/measurements/show?ids[]=${files.join('&ids[]=')}`);
};

const getModulus = (project_id: number, item_id: number, files) => {
  const http = createAxios()
  return http.get(`/projects/${project_id}/dataset/${item_id}/measurements/modulus?ids[]=${files.join('&ids[]=')}`);
};

const getPhase = (project_id: number, item_id: number, files) => {
  const http = createAxios()
  return http.get(`/projects/${project_id}/dataset/${item_id}/measurements/phase?ids[]=${files.join('&ids[]=')}`);
};

const getCircumference = (project_id: number, item_id: number, files) => {
  const http = createAxios()
  return http.get(`/projects/${project_id}/dataset/${item_id}/measurements/circumference?ids[]=${files.join('&ids[]=')}`);
};

const getParameters = (project_id: number, item_id: number, files) => {
  const http = createAxios()
  return http.get(`/projects/${project_id}/dataset/${item_id}/measurements/parameters?ids[]=${files.join('&ids[]=')}`);
};

const create = (project_id: number, item_id: number, files) => {
  const http = createAxios()
  return http.post(`/projects/${project_id}/dataset/${item_id}/measurements`, files);
};

// const remove = (project_id: number, item_id: number, files) => {
//   const http = createAxios()
//   return http.delete(`/projects/${project_id}/dataset/${item_id}/measurements`);
// };

const functions = {
  getAll,
  get,
  getModulus,
  getPhase,
  getCircumference,
  getParameters,
  create,
  // remove,
}

export default functions;