import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TablePanel  from './TablePanel'
import DataTableComponent from '../measurer/DataTable';
import GenericCard from '../common/GenericCard';

interface DataTableTabsProps {
  data: any[];
  handleSelectedData: (selectedData) => void;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
 
const DataTableTabs = (props: DataTableTabsProps) => {

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return ( 
    <GenericCard
      title={"Seleccionar Datos"}
    >
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

          {/* TABS */}

          <Tabs 
            value={value} 
            onChange={handleChange} 
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >

            {props.data.length > 0 && props.data.map((data, index) => {
              return (
                <Tab key={index} label={data.name} {...a11yProps(index)} />
              )
            })}
          </Tabs>

        </Box>
          {/* CONTENIDO DE LAS TABS */}

          {props.data.length > 0 && props.data.map((data, index) => {
              return (
                <TablePanel key={index} value={value} index={index}>
                  <DataTableComponent
                    title={data.name}
                    data={data.data}
                    selectable={true}
                    handleSelectedData={props.handleSelectedData}
                  />
                </TablePanel>
              )
          })}
      </Box>
    </GenericCard>
  );
}
 
export default DataTableTabs;