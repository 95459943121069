import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SimpleBackdrop from '../common/SimpleBackdrop';

interface Props {
  children: React.ReactNode;
  show: boolean;
  title: string;
  handleClose: () => void;
}

export default function GenericDialog(props: Props) {

  const { children, show, title, handleClose } = props
  
  return (
    <div>
      <Dialog open={show} onClose={handleClose}>
      <SimpleBackdrop/>
        <DialogTitle>{ title }</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Complete los campos y aprete en aceptar.
            Los campos con * son obligatorios.
          </DialogContentText>
          { children }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}