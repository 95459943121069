import React, { useState } from "react";
import DataSetInterface from "../../interfaces/Resources/DataSetInterface";
import sendRequest from "../../services/api/sendRequest";
import { AxiosError, AxiosResponse } from "axios";
import { handleAxiosError } from "../../services/api/handleAxiosError";
import { Box, Button, Container, Grid, TextField } from "@mui/material";
import ErrorAlert from "../common/ErrorAlert";
import { trackPromise } from 'react-promise-tracker';

interface Props {
  projectId: number,
  itemId: number,
  handleDataSetForm: (data) => void;
}

const DataSetAddForm = (props: Props) => {
  const initialItemState: DataSetInterface = {
    title: "",
    project_id: props.projectId,
    id: props.itemId,
  }

  const [formData, setFormData] = useState(initialItemState);
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  };

  const handleSubmit = (e) => {
    e.preventDefault()

    trackPromise(
    sendRequest(`/projects/${props.projectId}/dataset`, "CREATE", formData)
      .then((response: AxiosResponse) => {
        props.handleDataSetForm(response.data)
      })
      .catch((error: AxiosError) => {
        const errorObject = handleAxiosError(error)
        setError(errorObject.error)
      })
    );

  }
  
  return (
    <Container>
      {error && 
        <ErrorAlert
          error={error}
          handleClose={() => setError("") }
        />
      }
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextField
              name={"title"}
              value={formData.title}
              required
              fullWidth
              id="title"
              label="Título"
              onChange={handleInputChange}
              autoFocus
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Confirmar
        </Button>
      </Box>
    </Container>
  );
}
export default DataSetAddForm;