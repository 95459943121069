import { AxiosError } from 'axios';

export const handleAxiosError = (error: AxiosError) => {
  let errorStatus: number
  let errorText: string

  if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      
      errorStatus = error.response.status
      errorText = error.response.statusText

  } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      errorText = 'Se hizo la petición, pero no se recibió respuesta del servidor.'
      errorStatus = 404

  } else {
      // Something happened in setting up the request that triggered an Error
      errorText = error.message
      errorStatus = 500
  }

  return {
      status: errorStatus,
      error: errorText
  }
}