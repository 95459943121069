import { Box, Button } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import useWindowPosition from "../../services/custom_hooks/useWindowPosition";
import CardImage from "./CardImage";

interface AppFeatureProps {
  
}
 
const AppFeature = () => {

  const windowChecked = useWindowPosition('header')
  const navigate = useNavigate()

  const navigateToLearn = () => {
		navigate('/aprendizaje')
	} 

  return ( 
    <Box 
      id={'app-features'} 
      sx={(theme: Theme) => ({ 
        minHeight: '100vh', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
          flexDirection: "column"
        }
      })}
    >
      <CardImage
        title="Analiza tus datos automáticamente"
        description="Podrás analizar tus datos de bioimpedancia de manera automática."
        imageUrl={ process.env.PUBLIC_URL + '/assets/feature1.jpeg' }
        checked={windowChecked}
      />
      <CardImage
        title="Múltiples dispositivos soportados"
        description="Además de los dispositivos soportados, podrás añadir los tuyos."
        imageUrl={ process.env.PUBLIC_URL + '/assets/feature2.jpg' }
        checked={windowChecked}
      />
      {/* <Button
        type="submit"
        variant="contained"
        size="large"
        color="secondary"
        sx={{ mt: 3, mb: 2 }}
        onClick={navigateToLearn}
      >
          Comenzar
      </Button> */}
    </Box>
  );
}
 
export default AppFeature;