import { Box, Grid, Paper, Typography } from "@mui/material";
import AvatarCard from "../components/common/AvatarCard";

interface AboutPageProps {
  
}
 
const AboutPage= () => {

  const authors = [
    {
      title: "Tomás Villanueva Jousset",
      subheader: "Estudiante Licenciatura en Sistemas",
      avatarUrl: "/assets/AvatarTomas.jpeg",
      description: [`Es estudiante de la Licenciatura en Sistemas, y con la realización de BioAnalyZer
      consigue el título de pregrado de Analista Universitario en Sistemas.`,
      `En el mes de octubre de 2021, en un trabajo interdisciplinario consistente de biompedancia e informática,
      logra publicar un paper en la revista "43rd Annual International Conference of the IEEE Engineering 
      in Medicine & Biology Society (EMBC)".`,
      `También, participó en el Congreso Latinoamericano de Bioimpedancia (CLABIO2021) en caracter de orador invitado
      en la lecture denominada, Cole Mode: numerical applications, dictada por el Ing. Antonio H. Dell'Osa.`,
      `Actualmente se encuentra finalizando sus estudios en la Universidad Nacional de Tierra del Fuego, en la 
      carrera de grado de Licenciatura en Sistemas (con orientación a calidad de software).`,
      "",
      ""
      ]
    },
    {
      title: "Antonio H. Dell'Osa",
      subheader: "Ingenierio Biomédico",
      avatarUrl: "/assets/AvatarAntonio.jpeg",
      description: [`Es ingeniero biomédico (Univ. Favaloro, CABA, 2014) que se desempeña como
      docente en el área de Matemática e investigador en el campo de la biomedicina, en general, y en
      la bioimpedancia, en particular; funciones que desempeña como Profesor del Instituto de
      Desarrollo Económico e Innovación de la Universidad de Tierra del Fuego y becario doctoral del
      CONICET/UNTDF en la ciudad de Ushuaia (Tierra del Fuego).`,
      `En el tiempo más reciente recibió menciones destacadas en el “International Conference on
      Electrical Bioimpedance”, fue finalista del Concurso la Innovación Arcor 2021 y elegido por el
      programa Emprendé ConCiencia impulsado por la Fundación INVAP y el Ministerio de Desarrollo
      Productivo de la Nación Argentina; además de recibir becas de nivel nacional e internacional que
      lo llevaron a generar vinculación estable con universidades de México, Italia y Uruguay. Todo esto
      se ha volcado en numerosas publicaciones en Congresos y Revistas Científicas.`,
      `Actualmente es el responsable del Laboratorio de Electrónica Aplicada y Biomedicina, primer
      laboratorio de investigación en el área en el ámbito académico de la Patagonia Austral.`]
    }
  ]

  return (  
    <Box>
      <Grid container spacing={2}>

        <Grid 
            item 
            container
            display="flex"
            justifyContent="center"
          >
					<Typography variant={"h1"} component={"h1"} sx={{ color: 'white '}}>
						QUIÉNES SOMOS			
					</Typography>
				</Grid>

        <Grid item container direction="row" justifyContent="center" alignItems="flex-start">

          <AvatarCard
            title={authors[0].title}
            subheader={authors[0].subheader}
            avatarUrl={authors[0].avatarUrl}
            description={authors[0].description}
          />

          <AvatarCard
            title={authors[1].title}
            subheader={authors[1].subheader}
            avatarUrl={authors[1].avatarUrl}
            description={authors[1].description}
          />
        </Grid> 

        <Grid 
            item 
            container
            display="flex"
            justifyContent="center"
          >
					<Typography variant={"h1"} component={"h1"} sx={{ color: 'white '}}>
						SOBRE 
            <Box component="span" sx={{ color: 'secondary.main'}}> BIO</Box>
						ANALY
						<Box component="span" sx={{ color: 'secondary.main'}}>Z</Box>
						ER							
					</Typography>
				</Grid>

        {/* Esto debería ser un map de un arreglo... */}
        <Grid item container direction="row" justifyContent="center" alignItems="flex-start">
        <Grid item xs={12} sm={12} md={12}>
            <Typography variant="overline" paragraph color='white' fontSize={'1rem'} align="justify">
              La aplicación BioAnalyZer es una implementación a nivel de software de un método numérico de
              autoría propia 
              <a href='https://paperhost.org/proceedings/embs/EMBC21/files/0566.pdf'> (Villanueva et at. 2021) </a> 
              que permite obtener los datos característicos de la ecuación
              de Cole 
              <a href='http://symposium.cshlp.org/content/8/110'> (Cole et al 1940) </a>
              para mediciones espectroscópicas de bioimpedancia eléctrica.
            </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="overline" paragraph color='white' fontSize={'1rem'} align="justify">
              Este desarrollo informático se engendra como cumplimiento del trabajo final de la materia
              Laboratorio de Software de la currícula de la carrera de Licenciatura en Sistemas del Instituto de
              Desarrollo Económico e Innovación de la Universidad Nacional de Tierra del Fuego del estudiante
              Tomás Villanueva Jousset y una propuesta del Ing. Antonio H. Dell’Osa desde el Laboratorio de
              Electrónica Aplicada y Biomedicina.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="overline" paragraph color='white' fontSize={'1rem'} align="justify">
              BioAnalyZer permite que cualquier usuario pueda generar una cuenta, ingresar a la plataforma,
              cargar los archivos que contienen los datos medidos por diferentes dispositivos de bioimpedancia,
              compartirlo con otros usuarios registrados y visualizar los gráficos de sus resultados y parámetros
              numéricos de la ecuación de Cole calculados por la aplicación.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="overline" paragraph color='white' fontSize={'1rem'} align="justify">
              El primer objetivo del BioAnalyZer es brindar un soporte multiplataforma a usuarios no técnicos
              independientes. Además, la de concretar el trabajo interdisciplinario con implementación del
              método numérico iterativo desarrollado por el Ing. Dell’Osa en una herramienta informática
              accesible desde un sitio web; esta implementación fue generada por Tomás Villanueva Jousset. Es
              decir, INFORMÁTICA+MATEMÁTICA+BIOMEDICINA+INGENIERIA.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="overline" paragraph color='white' fontSize={'1rem'} align="justify">
              BioAnalyZer está diseñado para trabajar con archivos entregados por dispositivos como Solartron
              (Alemania), Zurich (Suiza), Xitron (USA), AD5933EBZ (USA), ScioSpec () AGREGA SI ME OLVIDO
              ALGUNO
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="overline" paragraph color='white' fontSize={'1rem'} align="justify">
              BioAnalyZer está contenido como una de las actividades que se mencionan en el Proyecto de
              Investigación y Desarrollo de la Universidad Nacional de Tierra del Fuego (PIDUNTDF “B”) dirigido
              por Ing. Dell’Osa y codirigido por el Lic. Prisching, el cual se denomina 
              <a href='https://www.untdf.edu.ar/uploads/archivos/B_2019_IDEI_DellOsa_1601317832.pdf'> “Detección de lesiones óseas por medio de bioimpedancia: pruebas clínicas, portabilidad y comercialización”.</a>
          </Typography>
        </Grid>
      </Grid>
      </Grid>
    </Box>
  );
}
 
export default AboutPage;