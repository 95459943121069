import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TablePanel  from './TablePanel'
import GenericCard from '../common/GenericCard';
import ParamatersInterface from '../../interfaces/ParametersInterface';
import ChipRow from '../dashboard/ChipRow';
import ErrorAlert from '../common/ErrorAlert';

interface ParametersTabsProps {
  data: ParamatersInterface[];
  circumference: boolean;
  error: string;
  errorClose: () => void;
}
 
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ParametersTabs = (props: ParametersTabsProps) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return ( 
    <GenericCard
      title={props.circumference ? "Parámetros de Circunferencia" : "Parámetros de Cole"}
    >
      {props.error && 
        <ErrorAlert
          error={props.error}
          handleClose={() => props.errorClose() }
        />
      }
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

          {/* TABS */}

          <Tabs 
            value={value} onChange={handleChange} 
            aria-label="basic tabs example" 
            variant="scrollable"
            scrollButtons
          >

            {props.data.length > 0 && props.data.map((data, index) => {
              return (
                <Tab wrapped key={index} label={data.name} {...a11yProps(index)} />
              )
            })}
          </Tabs>

        </Box>
          {/* CONTENIDO DE LAS TABS */}

          {props.data.length > 0 && props.data.map((data, index) => {
              return (
                <TablePanel key={index} value={value} index={index}>
                  <ChipRow 
                    data={data}
                    circumference={props.circumference}
                  />
                </TablePanel>
              )
          })}
      </Box>
    </GenericCard>
  );
}
 
export default ParametersTabs;