import React, { useState } from 'react'
import { Box, Container, Button } from "@mui/material";
import sendRequest from '../../services/api/sendRequest';
import { AxiosError, AxiosResponse } from 'axios';
import { handleAxiosError } from '../../services/api/handleAxiosError';
import ErrorAlert from '../common/ErrorAlert';
import { trackPromise } from 'react-promise-tracker';

interface Props {
	projectId: number,
	itemId: number,
	handleDataSetForm: (data) => void;
}

const DataSetDeleteForm = (props: Props) => {
  
	const [error, setError] = useState("");

	const handleSubmit = (e) => {
    e.preventDefault()

    trackPromise(
    sendRequest(`/projects/${props.projectId}/dataset/${props.itemId}`, "DELETE")
      .then((response: AxiosResponse) => {
        props.handleDataSetForm(response.data)
      })
      .catch((error: AxiosError) => {
        const errorObject = handleAxiosError(error)
        setError(errorObject.error)
      })
    );

  }

	return(
		<Container>
      {(error) && 
        <ErrorAlert
          error={error}
          handleClose={() => setError("") }
        />
      }
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="warning"
          sx={{ mt: 3, mb: 2 }}
        >
          Confirmar
        </Button>
      </Box>
    </Container>
	);
}
export default DataSetDeleteForm;