import { useEffect, useState } from "react"
import CookieService from "../CookieService"

export const useAuth = () => {

  let authenticated: boolean

  const token = CookieService.get("access_token");

  token ? authenticated = true : authenticated = false 

  return authenticated

}