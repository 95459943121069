import React, { useEffect, useState } from "react";
import DataSetInterface from "../../interfaces/Resources/DataSetInterface";
import DataSetAddForm from "./DataSetAddForm";
import DataSetEditForm from "./DataSetEditForm";
import DataSetDeleteForm from "./DataSetDeleteForm";
import GenericDialog from "../modals/GenericDialog";
import DataSetList from "./DataSetList";
import AddIcon from '@mui/icons-material/Add';
import sendRequest from "../../services/api/sendRequest";
import { AxiosError, AxiosResponse } from "axios";
import { handleAxiosError } from "../../services/api/handleAxiosError";
import { Box, IconButton, Tooltip } from "@mui/material";
import GenericCard from "../common/GenericCard";
import ErrorAlert from "../common/ErrorAlert";
import Loading from "../common/Loading";
import { trackPromise } from 'react-promise-tracker';

interface Props {
  project_id: number,
  handleSelectedItem: (id) => void,
}

const DataSetMain = (props: Props) => {

  const [datasets, setDataSets] = useState([] as DataSetInterface[]);
  const [targetItem, setTargetItem] = useState(-1);
  const [selectedItem, setSelectedItem] = useState(-1);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  //error y loading
  const [error, setError] = useState("");

  useEffect(() => {

    trackPromise(
    sendRequest(`projects/${props.project_id}/dataset`, "GET")
      .then((response: AxiosResponse) => {
        setDataSets(response.data)
      })
      .catch((error: AxiosError) => {
        const errorObject = handleAxiosError(error)
        setError(errorObject.error)
      })
    );

  }, [ props.project_id ]); 

  const onEdit = (id: number) => {
    setShowEditModal(!showEditModal);
    setTargetItem(id); 
  }

  const onDelete = (id: number) => {
    props.handleSelectedItem(-1);
    setShowDeleteModal(!showDeleteModal);
    setTargetItem(id);
    setSelectedItem(-1);
  }

  const onSelect = (id: number) => {
    props.handleSelectedItem(id);
    setSelectedItem(id);
  }

  const handleAddItem = async (data) =>{
    const dataset = data; 

    let newDataSets: DataSetInterface[];
    newDataSets = datasets;

    newDataSets = [...datasets, dataset]
    
    setDataSets(newDataSets);
    setShowAddModal(!showAddModal);
    // setSuccess(true)
  }

  const handleItemEditForm = async (data) => {
    const newItem = data;
    let newItems: DataSetInterface[];
    newItems = datasets;

    newItems = [...datasets];

    const isEditedItem = (item: DataSetInterface) => item.id === targetItem;

    let index: number = newItems.findIndex(isEditedItem);
    newItems[index] = newItem;

    setDataSets(newItems);
    setShowEditModal(!showEditModal);
  }

  const handleItemDeleteForm = (data) => {
    //const response = data;
    
    let newItems: DataSetInterface[];
    newItems = datasets.filter((item : DataSetInterface) => {
      return item.id !== targetItem;
    });

    setDataSets(newItems);
    setShowDeleteModal(!showDeleteModal);
    setTargetItem(-1)
  }

  return (
    <Box sx={{ height: '100%'}}>  
      <GenericCard
        title="Sets de datos"
      >
        {error && 
        <ErrorAlert
          error={error}
          handleClose={() => setError("") }
        />
      }
        <Box>
          {/* Para agregar data sets */}
          <Tooltip 
              disableFocusListener
              disableHoverListener
              title={"agregar dataset"}
          >
            <IconButton onClick={ () => setShowAddModal(true) }>
              <AddIcon/>
            </IconButton>
          </Tooltip>

          {/* Lista de datasets */}
          <DataSetList
            datasets={datasets}
            onSelect={(id) => onSelect(id)}
            onClickDelete={(id) => onDelete(id)}
            onClickEdit={(id) => onEdit(id)}
          />

          <GenericDialog 
            show={ showAddModal }
            title={"Agregar Data Set"}
            handleClose={() => setShowAddModal(!showAddModal)}
          >
            <DataSetAddForm 
              projectId={props.project_id}
              itemId={targetItem}
              handleDataSetForm={ (data) => handleAddItem(data) } 
            />
          </GenericDialog>
          <GenericDialog 
            show={ showEditModal }
            title={"Editar Data Set"}
            handleClose={() => setShowEditModal(!showEditModal)}
          >
            <Box>
              <DataSetEditForm 
                projectId={props.project_id}
                itemId={targetItem} 
                handleDataSetForm={(data) => handleItemEditForm(data) } 
              />
            </Box>
          </GenericDialog>
          <GenericDialog
            show={ showDeleteModal }
            title={"Eliminar Data Set"}
            handleClose={() => setShowDeleteModal(!showDeleteModal)}
          >
            <Box>
              <DataSetDeleteForm 
                projectId={props.project_id}
                itemId={targetItem}
                handleDataSetForm={ (data) => handleItemDeleteForm(data) }
              />
            </Box>
          </GenericDialog>     
        </Box>
      </GenericCard>
    </Box>
  );
}
export default DataSetMain;