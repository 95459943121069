import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DataSetList from "../components/datasets/DataSetMain";
import FileList from "../components/files/FileMain";
import FileService from "../services/FileService";
import LineChart from "../components/dashboard/LineChart";
import usePrevious from "../services/custom_hooks/usePrevious";
import SelectedDataInterface from "../interfaces/SelectedDataInterface";
import CircumferenceChart from "../components/dashboard/CircumferenceChart";
import FileExcelExport from "../components/files/FileExcelExport";
import ParametersTabs from "../components/layout/ParametersTabs";
import DataTableTabs from "../components/layout/DataTableTabs";
import { Grid, Paper } from "@mui/material";
import { trackPromise } from 'react-promise-tracker';
import { AxiosError } from "axios";
import { handleAxiosError } from "../services/api/handleAxiosError";

type Tparams = {
  userId: string
}

const Dashboard = () => {
  const { user_id } = useParams(); //id del proyecto
  const id = Number(user_id)
  const [selectedItem, setSelectedItem] = useState(-1);
  const [selectedFiles, setSelectedFiles] = useState([] as number[]);
  const [selectedFilesData, setSelectedFilesData] = useState([]);
  const [selectedFilesDataModulus, setSelectedFilesDataModulus] = useState([]);
  const [selectedFilesDataPhase, setSelectedFilesDataPhase] = useState([]);
  const [selectedFilesDataCircumference, setSelectedFilesDataCircumference] = useState([]);
  const [selectedFilesDataParameters, setSelectedFilesDataParameters] = useState([]);
  const [selectedData, setSelectedData] = useState({selectedData: [] as any[], name:""});
  const prevSelectedFiles = usePrevious(selectedFiles);

  //error y loading
  const [error, setError] = useState(false);
  const [errorData, setErrorData] = useState("");
  const [errorDataPhase, setErrorDataPhase] = useState("");
  const [errorDataModulus, setErrorDataModulus] = useState("");
  const [errorDataCircumference, setErrorDataCircumference] = useState("");
  const [errorDataParameters, setErrorDataParameter] = useState("");
  
  const handleSelectedItem = (id: number) => {
    setSelectedItem(id);
  };

  const handleSelectedFiles = (ids: number[]) => {
    setSelectedFiles(ids);
  };

  const handleSelectedData = (selectedData: SelectedDataInterface) => {
    setSelectedData(selectedData);
  }

  useEffect(() => {
    if (selectedItem !== -1 && prevSelectedFiles !== selectedFiles) {
      setError(false);
      setErrorData("");
      setErrorDataPhase("");
      setErrorDataModulus("");
      setErrorDataCircumference("");

      trackPromise(
      FileService.get(id, selectedItem, selectedFiles)
        .then((response) => {
          const data = response.data;
          setSelectedFilesData(data);
        })
        .catch((error: AxiosError) => {
          const errorObject = handleAxiosError(error)
          setErrorData(errorObject.error)
        })
      );  

      trackPromise(
      FileService.getModulus(id, selectedItem, selectedFiles)
        .then((response) => {
          const data = response.data;
          setSelectedFilesDataModulus(data);
        })
        .catch((error: AxiosError) => {
          const errorObject = handleAxiosError(error)
          setErrorDataModulus(errorObject.error)
        })
      );

      trackPromise(  
      FileService.getPhase(id, selectedItem, selectedFiles)
        .then((response) => {
          const data = response.data;
          setSelectedFilesDataPhase(data);
        })
        .catch((error: AxiosError) => {
          const errorObject = handleAxiosError(error)
          setErrorDataPhase(errorObject.error)
        })
      );  

      trackPromise(
      FileService.getCircumference(id, selectedItem, selectedFiles)
        .then((response) => {
          const data = response.data;
          setSelectedFilesDataCircumference(data);
        })
        .catch((error: AxiosError) => {
          const errorObject = handleAxiosError(error)
          setErrorDataCircumference(errorObject.error)
        })
      );

      trackPromise(
      FileService.getParameters(id, selectedItem, selectedFiles)
        .then((response) => {
          const data = response.data;
          setSelectedFilesDataParameters(data);
        })
        .catch((error: AxiosError) => {
          const errorObject = handleAxiosError(error)
          setErrorDataParameter(errorObject.error)
        })
      );

    }
    return () => {
      setSelectedFilesData([]);
      setSelectedFilesDataModulus([]);
      setSelectedFilesDataPhase([]);
      setSelectedFilesDataCircumference([]);
      setSelectedFilesDataParameters([]);
    };
  }, [selectedFiles, id, selectedItem, prevSelectedFiles]);

  return (
    <Grid 
      container 
      spacing={1}
      sx={{ 
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      {/* INPUT */}
      <Grid 
        item
        xs={12} sm={12} md={6} lg={6} xl={6}
      >
        <Paper sx={{ height: '100%'}}>
          <DataSetList
            project_id={id}
            handleSelectedItem={(id) => handleSelectedItem(id)}
          />
        </Paper>
      </Grid>

      <Grid 
        item
        xs={12} sm={12} md={6} lg={6} xl={6}
      >
        <Paper sx={{ height: '100%'}}>
          <FileList
            project_id={id}
            item_id={selectedItem}
            handleSelectedFiles={handleSelectedFiles}
          /> 
        </Paper>
      </Grid>

      {/* GRÁFICOS/DISPLAY */}
      <Grid 
        item
        xs={12} sm={12} md={6} lg={6} xl={6}
      >
        <Paper sx={{ height: '100%'}}>
          <LineChart 
            title="Cole" 
            data={selectedFilesData}
            selectedData={selectedData}
            error={errorData}
            errorClose={ () => setErrorData("") }
          />
        </Paper>
      </Grid>

      <Grid 
        item
        xs={12} sm={12} md={6} lg={6} xl={6}
      >
        <Paper sx={{ height: '100%'}}>
          <CircumferenceChart
            title="Circunferencia"
            error={errorDataCircumference}
            data={selectedFilesDataCircumference}
            errorClose={ () => setErrorDataCircumference("") }
          />
        </Paper>
      </Grid>

      {/* parameters */}

      <Grid
        item
        xs={12} sm={12} md={6} lg={6} xl={6}
      >
        <Paper sx={{ height: '100%'}}>
          <ParametersTabs
            circumference={false}
            data={selectedFilesDataParameters}
            error={errorDataParameters}
            errorClose={ () => setErrorDataParameter("") }
          />
        </Paper>
      </Grid>

      <Grid
        item
        xs={12} sm={12} md={6} lg={6} xl={6}
      >
        <Paper sx={{ height: '100%'}}>
          <ParametersTabs
            circumference={true}
            data={selectedFilesDataParameters}
            error={errorDataParameters}
            errorClose={ () => setErrorDataParameter("") }
          />
        </Paper>
      </Grid>

      {/* más graficos */}

      <Grid 
        item
        xs={12} sm={12} md={6} lg={6} xl={6}
      >
        <Paper sx={{ height: '100%'}}>
          <LineChart
            title="Módulo vs Frecuencia"
            data={selectedFilesDataModulus}
            selectedData={selectedData}
            error={errorDataModulus}
            errorClose={ () => setErrorDataModulus("") }
          />
        </Paper>
      </Grid>

      <Grid 
        xs={12} sm={12} md={6} lg={6} xl={6}
        item
      >
        <Paper sx={{ height: '100%'}}>
          <LineChart
            title="Fase vs Frecuencia"
            data={selectedFilesDataPhase}
            selectedData={selectedData}
            error={errorDataPhase}
            errorClose={ () => setErrorDataPhase("") }
          />
        </Paper>
      </Grid>

      {/* DATOS EXTRA */}
      
      <Grid
        item
        xs={12} sm={12} md={12} lg={12} xl={12}
      > 
        <DataTableTabs
          data={selectedFilesData}
          handleSelectedData={handleSelectedData}
        />

      </Grid>

      <Grid
        item
        xs={12} sm={12} md={12} lg={12} xl={12}
      >
        <FileExcelExport
          selectedData={selectedFilesData}
          modulusData={selectedFilesDataModulus}
          phaseData={selectedFilesDataPhase}
          parametersData={selectedFilesDataParameters}   
        />
      </Grid>

    </Grid>
  );
};
export default Dashboard;
