import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import sendRequest from '../services/api/sendRequest';
import { Paper } from '@mui/material';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { handleAxiosError } from '../services/api/handleAxiosError';
import ErrorAlert from '../components/common/ErrorAlert';
import ActivationPage from './ActivationPage';
import { trackPromise } from 'react-promise-tracker';

const RegisterPage = () => {

  const navigate = useNavigate()

  const [error, setError] = React.useState("")
  const [success, setSuccess] = React.useState(false)

  React.useEffect(() => {
    setTimeout(() => {
      setError("")
    }, 5000)
  }, [error])

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const dataToSend = {
      name: data.get('name'),
      lastName: data.get('lastName'),
      email: data.get('email'),
      password: data.get('password'),
      password_confirmation: data.get('password_confirmation'),
    }

    trackPromise(
    sendRequest('/users/register', "CREATE", dataToSend)
      .then((response: AxiosResponse) => {
        setSuccess(true)
      })
      .catch((error: AxiosError) => {
        const errorObject = handleAxiosError(error)
        setError(errorObject.error)
      })
    );
  };

  if(success) {
    return <ActivationPage/>
  } else {
  return (
    <Container component="main" maxWidth="xs">
      <Paper 
        sx={{
          width: '100%',
        }}>
      <Box
        sx={{
          marginTop: 8,
          marginLeft: 8,
          marginRight: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Registrarse
        </Typography>
        {error && 
          <ErrorAlert
            error={error}
            handleClose={() => setError("") }
          />
        }
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                name="name"
                required
                fullWidth
                id="name"
                label="Nombre"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="lastName"
                label="Apellido"
                name="lastName"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password_confirmation"
                label="Confirmar Contraseña"
                type="password"
                id="password_confirmation"
                autoComplete="new-password"
              />
            </Grid>
            {/* <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="Quiero recibir noticias al email."
              />
            </Grid> */}
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Registro
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Ya tienes una cuenta? Inicia sesión
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      </Paper>
    </Container>
  );}
}
export default RegisterPage;