import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Checkbox, TablePagination, Typography } from '@mui/material';
import ProjectTableToolbar from './ProjectTableToolbar';
import SerializedProjectInterface from '../../interfaces/Resources/ProjectInterface';
import { styleConstants } from '../../services/Constants';
import ProjectList from '../../pages/ProjectsPage';

interface Props {
  projects: SerializedProjectInterface[];
  onClickAdd: () => void;
  onClickCollab: (id) => void;
  onClickDelete: (id) => void;
  onClickEdit: (id) => void;
}

interface Selected {
  title: string;
  id: number;
  selected: boolean;
}

const ProjectTable = (props: Props) => {

  const { projects, onClickAdd, onClickCollab, onClickDelete, onClickEdit } = props

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selected, setSelected] = React.useState<Selected[]>([]);
  const [selectedProjectTitle, setSelectedProjectTitle] = React.useState("")
  const [selectedProjectId, setSelectedProjectId] = React.useState(-1)

  React.useEffect(() => {

    const newProjects = projects.map(function(project){
      let obj: Selected = {} as Selected

      obj.title = project.title
      obj.id = project.id

      if(project.id === selectedProjectId)
        obj.selected = true
      else 
        obj.selected = false
      
      return obj
    })

    setSelected(newProjects)
  }, [ projects ])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  }

  const handleClick = (event: React.MouseEvent<unknown>, idToSelect: number) => {
    // let newSelected: Selected[] = []
    const selectedIndex = selected.map(function(e) { return e.selected }).indexOf(true)
    const toSelectIndex = selected.map(function(e) { return e.id }).indexOf(idToSelect)

    const toSelectProject: Selected = selected[toSelectIndex]

    if(selectedIndex !== -1) {
      const selectedProject: Selected = selected[selectedIndex]

      if(selectedProject.id !== idToSelect){
        toSelectProject.selected = true
        setSelectedProjectTitle(toSelectProject.title)
        setSelectedProjectId(toSelectProject.id)
      } else {
        setSelectedProjectTitle("")
        setSelectedProjectId(-1)
      }

      selectedProject.selected = false
    } else {
      toSelectProject.selected = true
      setSelectedProjectTitle(toSelectProject.title)
      setSelectedProjectId(toSelectProject.id)
    }
    // setSelected(newSelected);
  }

  const isSelected = (id: number) => {
    const selectedIndex = selected.map(function(e) { return e.id }).indexOf(id)

    if(selectedIndex !== -1){
      return selected[selectedIndex].selected
    } else {
      return false
    }
  }

  const isDeleted = (id: number) => {
    const selectedIndex = selected.map(function(e) { return e.id }).indexOf(id)

    return selectedIndex === -1 ? true : false
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  // Esto lo copio de la documentación... ni idea 
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - projects.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <ProjectTableToolbar 
          tableTitle="Proyectos"
          titleSelected={ isDeleted(selectedProjectId) ? "" : selectedProjectTitle }
          idSelected={selectedProjectId}
          onClickAdd={ onClickAdd }
          onClickCollab={ (id) => onClickCollab(selectedProjectId) }
          onClickDelete={ (id) => onClickDelete(selectedProjectId) }
          onClickEdit={ (id) => onClickEdit(selectedProjectId) }
        />
        <TableContainer>
          <Table sx={{ minWidth: `calc(100% - ${styleConstants.drawerWidth}px)` }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox"/>
                <TableCell>Título</TableCell>
                <TableCell padding={'normal'} align="left">Descripción</TableCell>
                <TableCell padding={'normal'} align="left">Rol</TableCell>
                <TableCell padding={'normal'} align="left">Fecha</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(
                rowsPerPage > 0 
                  ? projects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : projects
              ).map((project, index) => {
                const isItemSelected = isSelected(project.id);
                const labelId = `table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, project.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={project.id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{ 
                        minWidth: 20,
                        maxWidth: 50, 
                      }}
                    >
                      <Typography noWrap variant={"body2"}>
                        {project.title}
                      </Typography>
                     
                    </TableCell>
                    <TableCell 
                      align="left"
                      sx={{ 
                        minWidth: 20,
                        maxWidth: 50, 
                      }}
                    >
                      <Typography noWrap variant={"body2"}>
                        {project.description}
                        </Typography>

                    </TableCell>
                    <TableCell align="left">{project.role}</TableCell>
                    <TableCell align="left">{formatDate(project.created_at)}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={projects.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              // onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
      </Paper>
    </Box>                
  );
}

const formatDate = (date: string) => {
  return `${date.substring(8,10)}/${date.substring(5,7)}/${date.substring(0,4)}`
}

export default ProjectTable;