import { Alert, AlertTitle, Box, IconButton } from "@mui/material";
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

interface ErrorAlertProps {
  error:string
  handleClose: () => void;
}
 
const ErrorAlert = (props: ErrorAlertProps) => {
  return ( 
    <Box sx={{ width: '100%' }}>
      <Collapse in={ Boolean(props.error) }>
        <Alert 
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                props.handleClose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle>Error</AlertTitle>
          {props.error} — <strong>revise y vuelva a intentar!</strong>
        </Alert>
      </Collapse>
    </Box>
  );
}
 
export default ErrorAlert;