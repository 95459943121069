import axios from "axios";
import CookieService from "./CookieService";

const createAxios = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      Authorization: "Bearer " + CookieService.get("access_token"),
    },
  })
}

export default createAxios;
