export const colors = [
                'rgb(153, 0, 0)', 
                'rgb(0, 153, 0)',
                'rgb(0, 0, 153)',
                'rgb(38, 153, 0)',
                'rgb(0, 38, 153)',
                'rgb(153, 0, 38)',
                'rgb(153, 115, 0)',
                'rgb(0, 153, 153)',
                'rgb(153, 0, 153)',
                'rgb(153, 51, 0)',
                'rgb(0, 153, 77)',
                'rgb(77, 0, 153)',
                'rgb(115, 153, 0)',
                'rgb(0, 115, 153)',
                'rgb(153, 0, 115)',
                'rgb(153, 77, 0)',
                'rgb(0, 153, 115)',
                'rgb(115, 0, 153)',
                'rgb(153, 38, 0)',
                'rgb(0, 153, 38)',
                'rgb(77, 153, 0)',
                'rgb(38, 0, 153)',
                'rgb(153, 153, 0)',   
                'rgb(0, 77, 153)',                       
                'rgb(153, 0, 77)',              
              ];
