import React, { useState, useEffect } from 'react'
import FileInterface from '../../interfaces/FileInterface'
import FilesAddForm from './FilesAddForm';
import GenericDialog from '../modals/GenericDialog';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import { Box, Button, FormControl, IconButton, InputLabel, Select, Tooltip } from '@mui/material';
import sendRequest from '../../services/api/sendRequest';
import { AxiosError, AxiosResponse } from 'axios';
import { handleAxiosError } from '../../services/api/handleAxiosError';
import GenericCard from '../common/GenericCard';
import ErrorAlert from "../common/ErrorAlert";
import { trackPromise } from 'react-promise-tracker';

interface Props {
  project_id: number,
  item_id: number,
  handleSelectedFiles: (ids) => void,
}

const FileMain = (props: Props) => {
  const [files, setFiles] = useState([] as FileInterface[]);
  const [selectedFiles, setSelectedFiles] = useState([] as string[]);
  const [showLoadFilesModal, setShowLoadFilesModal] = useState(false);

  //error y loading
  const [error, setError] = useState("");

  useEffect(() => {
		if(props.item_id !== -1){
			
    trackPromise(  
    sendRequest(`/projects/${props.project_id}/dataset/${props.item_id}/measurements`, "GET")
      .then((response: AxiosResponse) => {
        setFiles(response.data);
      })
      .catch((error: AxiosError) => {
        const errorObject = handleAxiosError(error)
        setError(errorObject.error)
      })
    );
      
		}

    return () => {
      setFiles([] as FileInterface[]);
      setSelectedFiles([] as string[]);
    }
	}, [ props.item_id, props.project_id ]);

  const handleAddFiles = (data) => {
    
    const uploadedFiles = data
    let newFiles: FileInterface[]
    newFiles = files

    // newFiles = [...files, uploadedFiles]
    newFiles = files.concat(uploadedFiles)

    setFiles(newFiles)

    setShowLoadFilesModal(false);
  }

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();

    const { options } = event.target;
    const value: string[] = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setSelectedFiles(value);
  }

  return (
    <Box sx={{ height: '100%' }}>  
      <GenericCard
        title="Archivos"
      >
      {error && 
        <ErrorAlert
          error={error}
          handleClose={() => setError("") }
        />
      }
        <Box>
          {/* Para agregar data sets */}
          <Tooltip 
            disableFocusListener
            disableHoverListener
            title={"subir archivos"}
          >
            <span>
            <IconButton 
              onClick={ () => setShowLoadFilesModal(true) }
              disabled={props.item_id === -1}
            >
              <FileUploadIcon/>
            </IconButton>
            </span>
          </Tooltip>

          {/* Para seleccionar los archivos */}
          <Box sx={{ width: '100%' }}>
            <FormControl sx={{ m: 1, width: '100%' }}>
              <InputLabel shrink htmlFor="select-multiple-files">
                Archivos
              </InputLabel>
              <Select
                multiple
                native
                value={selectedFiles}
                // @ts-ignore Typings are not considering `native`
                onChange={handleSelectChange}
                label="Archivos"
                inputProps={{
                  id: 'select-multiple-files',
                }}
              >
                {files.length > 0 && files.map((file) => {
                  return (
                    <option key={file.id} value={file.id}>
                      {file.client_name}
                    </option>
                  )
                })}
              </Select>
            </FormControl>
          </Box>
          {/* Botón para graficar */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={(selectedFiles.length === 0 ? true: false)}
            startIcon={ <AutoGraphIcon/>}
            onClick={() => props.handleSelectedFiles(selectedFiles) }
            sx={{ mt: 3, mb: 2 }}
          >
            Graficar
          </Button>
          <GenericDialog 
              show={ showLoadFilesModal }
              title={"Agregar Archivos"}
              handleClose={() => setShowLoadFilesModal(!showLoadFilesModal)}
            >
              <Box>
                <FilesAddForm 
                  projectId={props.project_id}
                  itemId={props.item_id}
                  handleAddFiles={handleAddFiles}
                  modal={showLoadFilesModal}
                />
              </Box>
          </GenericDialog>
        </Box>
      </GenericCard>
    </Box>  
  )
}
export default FileMain;