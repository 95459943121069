import React, { useState } from "react";
import { Box, Button, Container, Grid, TextField } from "@mui/material";
import ErrorAlert from "../common/ErrorAlert";
import Loading from "../common/Loading";
import sendRequest from "../../services/api/sendRequest";
import { AxiosError, AxiosResponse } from "axios";
import { handleAxiosError } from "../../services/api/handleAxiosError";
import { trackPromise } from 'react-promise-tracker';

interface CollaboratorFormProps {
    project_id: number;
    handleProjectForm: () => void
}
 
const CollaboratorForm = (props: CollaboratorFormProps) => {

    const [email, setEmail] = useState("")
    const [error, setError] = useState("")

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setEmail(value);
    };

    const handleSubmit = (event) => {
      event.preventDefault();

      trackPromise(
      sendRequest(`/projects/${props.project_id}/colaborador?email=${email}`, "UPDATE")
        .then((response: AxiosResponse) => {
            props.handleProjectForm()
        })
        .catch((error: AxiosError) => {
            const errorObject = handleAxiosError(error)
            setError(errorObject.error)
        })
      );    

      setEmail("")
    }

    return (
        <Container>
      {error && 
        <ErrorAlert
          error={error}
          handleClose={() => setError("") }
        />
      }
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextField
              name={"email"}
              value={email}
              required
              fullWidth
              id="email"
              label="Email de colaborador"
              onChange={handleInputChange}
              autoFocus
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Confirmar
        </Button>
      </Box>
    </Container>
    );
}
 
export default CollaboratorForm;