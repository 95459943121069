import React, { useEffect, useState } from 'react'
import MeasurerInterface from '../../interfaces/MeasurerInterface';
import { Box, Button, Grid, TextField } from '@mui/material';
import ErrorAlert from '../common/ErrorAlert';
import GenericCard from '../common/GenericCard';
import sendRequest from '../../services/api/sendRequest';
import { AxiosError, AxiosResponse } from 'axios';
import { handleAxiosError } from '../../services/api/handleAxiosError';
import { trackPromise } from 'react-promise-tracker';

interface MeasurerFormProps {
  delimiter: string;
  handleFormSubmit: () => void;
}

const MeasurerForm = (props: MeasurerFormProps) => {

  const initialMeasurerState = {
    name:"",
    fr:"",
    delimiter:"",
    real: "",
    imaginary: "",
    modulus: "",
    phase: "",
    fromLine: 0,
    created_at: "",
    updated_at: "",
    isPersonal: true,
  };

  const [formData, setFormData] = useState(initialMeasurerState);
  const [error, setError] = useState("")

  useEffect(() => {
    setFormData({ ...formData, ["delimiter"]: props.delimiter });
  }, [props.delimiter])

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    let data: MeasurerInterface = {
      id: 0,
      name: formData.name,
      fr: formData.fr,
      delimiter: formData.delimiter,
      real: formData.real === "" ? undefined : formData.real,
      imaginary: formData.imaginary === undefined ? "" : formData.imaginary,
      modulus: formData.modulus === "" ? undefined : formData.modulus,
      phase: formData.phase === "" ? undefined : formData.phase,
      fromLine: formData.fromLine,
      created_at: "",
      updated_at: "",
      isPersonal: formData.isPersonal,
    }

    trackPromise(
    sendRequest(`/measurers`, "CREATE", data)
      .then((response: AxiosResponse) => {
        props.handleFormSubmit()
      })
      .catch((error: AxiosError) => {
        const errorObject = handleAxiosError(error)
        setError(errorObject.error)
      })
    );  

    setFormData(initialMeasurerState)  
  };
  
  return (
    <Box>
      <GenericCard
        title="Datos del medidor"
      >
      {error && 
        <ErrorAlert
          error={error}
          handleClose={() => setError("") }
        />
      }
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3, height: '100%' }}>
        <Grid container spacing={2}>      
          <Grid item xs={12} sm={12}>
            <TextField
              name={"name"}
              value={formData.name}
              fullWidth
              id="name"
              label="Nombre"
              onChange={handleInputChange}
              autoFocus
              required
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              name={"delimiter"}
              value={formData.delimiter}
              fullWidth
              id="delimiter"
              label="Delimitador"
              onChange={handleInputChange}
              helperText="Por defecto se utiliza el autodetectado"
              required
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              name={"fr"}
              value={formData.fr}
              fullWidth
              id="fr"
              label="Frecuencia"
              onChange={handleInputChange}
              helperText="Número de columna donde se encuentra la frecuencia"
              required
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              name={"real"}
              value={formData.real}
              fullWidth
              id="real"
              label="Parte Real"
              onChange={handleInputChange}
              helperText="Número de columna donde se encuentra la parte real"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              name={"imaginary"}
              value={formData.imaginary}
              fullWidth
              id="imaginary"
              label="Parte imaginaria"
              onChange={handleInputChange}
              helperText="Número de columna donde se encuentra la parte imaginaria"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              name={"modulus"}
              value={formData.modulus}
              fullWidth
              id="modulus"
              label="Módulo"
              onChange={handleInputChange}
              helperText="Número de columna donde se encuentra el módulo"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              name={"phase"}
              value={formData.phase}
              fullWidth
              id="phase"
              label="Fase"
              onChange={handleInputChange}
              helperText="Número de columna donde se encuentra la fase"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              name={"fromLine"}
              value={formData.fromLine}
              fullWidth
              id="fromLine"
              label="Desde línea"
              onChange={handleInputChange}
              helperText="Número de fila desde la que se debería comenzar a parsear"
              required
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Confirmar
        </Button>
      </Box>
      </GenericCard>
    </Box>
  )
}

export default MeasurerForm;
