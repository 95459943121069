import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupsIcon from '@mui/icons-material/Groups';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom'

interface TableToolbarProps {
  tableTitle: string;
  titleSelected: string;
  idSelected:number;
  onClickAdd: () => void;
  onClickCollab: (id) => void;
  onClickDelete: (id) => void;
  onClickEdit: (id) => void;
}

const TableToolbar = (props: TableToolbarProps) => {
  const { tableTitle, titleSelected, idSelected, onClickAdd, onClickCollab, onClickDelete, onClickEdit } = props;
  const navigate = useNavigate()

  const navigateToDashboard = () => {
    navigate(`/dashboard/${idSelected}`)
  }

  const tooltipItems = [
    {
      title: "Edit",
      onOpen: onClickEdit,
      icon: <EditIcon/>
    },
    {
      title: "Delete",
      onOpen: onClickDelete,
      icon: <DeleteIcon/>
    },
    {
      title: "Dashboard",
      onOpen: navigateToDashboard,
      icon: <DashboardIcon/>
    },
    {
      title:"Colaboradores",
      onOpen: onClickCollab,
      icon: <GroupsIcon/>
    }
  ]

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(titleSelected !== "" && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {titleSelected !== "" ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {titleSelected} seleccionado
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {tableTitle}
        </Typography>
      )}
      {titleSelected !== "" ? (
        <Box>
          {tooltipItems.map((item, idx) => (

            <Tooltip 
              disableFocusListener
              disableHoverListener
              title={item.title}
              key={idx}
            >
              <IconButton onClick={ (idSelected) => item.onOpen(idSelected) }>
                {item.icon}
              </IconButton>
            </Tooltip>

          ))}
        </Box>
      ) : (
        <Tooltip 
          disableFocusListener
          disableHoverListener
          title="Add project"
        >
          <IconButton onClick={ onClickAdd }>
            <AddIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

export default TableToolbar;