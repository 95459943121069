import React from 'react'
import ProfileInterface from "../../interfaces/Resources/ProfileInterface";
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';

interface ProfileListProps {
  profile: ProfileInterface;
}

const ProfileList = (props: ProfileListProps) => {

  const { profile } = props

  const profileItems = [
    {
      title: "Nombre",
      description: profile.name,
      icon: <FaceIcon />,
    },
    {
      title: "Apellido",
      description: profile.last_name,
      icon: <FaceIcon />,
    },
    {
      title: "Institución",
      description: profile.institution,
      icon: <FaceIcon />,
    },
    {
      title: "Función",
      description: profile.function,
      icon: <FaceIcon />,
    },
    {
      title: "País",
      description: profile.country,
      icon: <FaceIcon />,
    },
    {
      title: "Nacionalidad",
      description: profile.nationality,
      icon: <FaceIcon />,
    },
    {
      title: "Fecha de Nacimiento",
      description: profile.birth_date,
      icon: <FaceIcon />,
    },
  ]

  return (
    <Box>
      <List>
        {profileItems.map((item, index) => {
          return(
            <ListItem key={index}>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={`${item.title}:`}
                secondary={`${item.description}`}
              />
            </ListItem>
          )
        })}
      </List>
    </Box>
  );
}

export default ProfileList;