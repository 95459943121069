import { Box, Grid, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ProfileForm from '../components/auth/ProfileForm';
import ProfileInterface from '../interfaces/Resources/ProfileInterface';
import ProfileList from '../components/profile/ProfileList';
import sendRequest from '../services/api/sendRequest';
import { AxiosError, AxiosResponse } from 'axios';
import { handleAxiosError } from '../services/api/handleAxiosError';
import ErrorAlert from '../components/common/ErrorAlert';
import { trackPromise } from 'react-promise-tracker';

const ProfilePage = () => {

  const [profile, setProfile] = useState({} as ProfileInterface);

   // error y cargando 
   const [error, setError] = useState("");
  
  useEffect(() => {
    setError("");

    trackPromise(
    sendRequest('/users/profile', "GET")
      .then((response: AxiosResponse) => {
        setProfile(response.data);
      })
      .catch((error: AxiosError) => {
        const errorObject = handleAxiosError(error)
        setError(errorObject.error)
      })
    );
    
  }, []);

  const handleProfileForm = async (data) => {
    const profile = data;
    setProfile(profile); 
  }
  
  return (
   <Box>
      {error && 
        <ErrorAlert
          error={error}
          handleClose={() => setError("") }
        />
       }
     <Grid container spacing={2}>

     <Grid item sm={12} md={6}>
        <Paper sx={{ height: '100%' }}>
          <ProfileList
            profile={profile}
          /> 
        </Paper>  
      </Grid>
      <Grid item sm={12} md={6}>
        <Paper sx={{ p: 1, height: '100%'}}>
          <ProfileForm
            profile={profile}
            handleProfileForm={(data) => handleProfileForm(data)}
          />
        </Paper>
      </Grid>
    </Grid>
  </Box>

  )
}
export default ProfilePage;