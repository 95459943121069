// Esto debería estar en un .env o algo así

const countries = [
  "Afganist\u00e1n",
  "Albania",
  "Alemania",
  "Andorra",
  "Angola",
  "Anguila",
  "Ant\u00e1rtida",
  "Antigua y Barbuda",
  "Arabia Saud\u00ed",
  "Argelia",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaiy\u00e1n",
  "Bahamas",
  "Banglad\u00e9s",
  "Barbados",
  "Bar\u00e9in",
  "B\u00e9lgica",
  "Belice",
  "Ben\u00edn",
  "Bermudas",
  "Bielorrusia",
  "Bolivia",
  "Bosnia y Herzegovina",
  "Botsuana",
  "Brasil",
  "Brun\u00e9i",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "But\u00e1n",
  "Cabo Verde",
  "Camboya",
  "Camer\u00fan",
  "Canad\u00e1",
  "Caribe neerland\u00e9s",
  "Catar",
  "Chad",
  "Chequia",
  "Chile",
  "China",
  "Chipre",
  "Ciudad del Vaticano",
  "Colombia",
  "Comoras",
  "Congo",
  "Corea del Norte",
  "Corea del Sur",
  "Costa Rica",
  "C\u00f4te d\u2019Ivoire",
  "Croacia",
  "Cuba",
  "Curazao",
  "Dinamarca",
  "Dominica",
  "Ecuador",
  "Egipto",
  "El Salvador",
  "Emiratos \u00c1rabes Unidos",
  "Eritrea",
  "Eslovaquia",
  "Eslovenia",
  "Espa\u00f1a",
  "Estados Unidos",
  "Estonia",
  "Esuatini",
  "Etiop\u00eda",
  "Filipinas",
  "Finlandia",
  "Fiyi",
  "Francia",
  "Gab\u00f3n",
  "Gambia",
  "Georgia",
  "Ghana",
  "Gibraltar",
  "Granada",
  "Grecia",
  "Groenlandia",
  "Guadalupe",
  "Guam",
  "Guatemala",
  "Guayana Francesa",
  "Guernsey",
  "Guinea",
  "Guinea Ecuatorial",
  "Guinea-Bis\u00e1u",
  "Guyana",
  "Hait\u00ed",
  "Honduras",
  "Hungr\u00eda",
  "India",
  "Indonesia",
  "Irak",
  "Ir\u00e1n",
  "Irlanda",
  "Isla Bouvet",
  "Isla de Man",
  "Isla de Navidad",
  "Isla Norfolk",
  "Islandia",
  "Islas \u00c5land",
  "Islas Caim\u00e1n",
  "Islas Cocos",
  "Islas Cook",
  "Islas Feroe",
  "Islas Georgia del Sur y Sandwich del Sur",
  "Islas Heard y McDonald",
  "Islas Malvinas",
  "Islas Marianas del Norte",
  "Islas Marshall",
  "Islas menores alejadas de EE. UU.",
  "Islas Pitcairn",
  "Islas Salom\u00f3n",
  "Islas Turcas y Caicos",
  "Islas V\u00edrgenes Brit\u00e1nicas",
  "Islas V\u00edrgenes de EE. UU.",
  "Israel",
  "Italia",
  "Jamaica",
  "Jap\u00f3n",
  "Jersey",
  "Jordania",
  "Kazajist\u00e1n",
  "Kenia",
  "Kirguist\u00e1n",
  "Kiribati",
  "Kuwait",
  "Laos",
  "Lesoto",
  "Letonia",
  "L\u00edbano",
  "Liberia",
  "Libia",
  "Liechtenstein",
  "Lituania",
  "Luxemburgo",
  "Macedonia del Norte",
  "Madagascar",
  "Malasia",
  "Malaui",
  "Maldivas",
  "Mali",
  "Malta",
  "Marruecos",
  "Martinica",
  "Mauricio",
  "Mauritania",
  "Mayotte",
  "M\u00e9xico",
  "Micronesia",
  "Moldavia",
  "M\u00f3naco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Mozambique",
  "Myanmar (Birmania)",
  "Namibia",
  "Nauru",
  "Nepal",
  "Nicaragua",
  "N\u00edger",
  "Nigeria",
  "Niue",
  "Noruega",
  "Nueva Caledonia",
  "Nueva Zelanda",
  "Om\u00e1n",
  "Pa\u00edses Bajos",
  "Pakist\u00e1n",
  "Palaos",
  "Panam\u00e1",
  "Pap\u00faa Nueva Guinea",
  "Paraguay",
  "Per\u00fa",
  "Polinesia Francesa",
  "Polonia",
  "Portugal",
  "Puerto Rico",
  "RAE de Hong Kong (China)",
  "RAE de Macao (China)",
  "Reino Unido",
  "Rep\u00fablica Centroafricana",
  "Rep\u00fablica Democr\u00e1tica del Congo",
  "Rep\u00fablica Dominicana",
  "Reuni\u00f3n",
  "Ruanda",
  "Ruman\u00eda",
  "Rusia",
  "S\u00e1hara Occidental",
  "Samoa",
  "Samoa Americana",
  "San Bartolom\u00e9",
  "San Crist\u00f3bal y Nieves",
  "San Marino",
  "San Mart\u00edn",
  "San Pedro y Miquel\u00f3n",
  "San Vicente y las Granadinas",
  "Santa Elena",
  "Santa Luc\u00eda",
  "Santo Tom\u00e9 y Pr\u00edncipe",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leona",
  "Singapur",
  "Sint Maarten",
  "Siria",
  "Somalia",
  "Sri Lanka",
  "Sud\u00e1frica",
  "Sud\u00e1n",
  "Sud\u00e1n del Sur",
  "Suecia",
  "Suiza",
  "Surinam",
  "Svalbard y Jan Mayen",
  "Tailandia",
  "Taiw\u00e1n",
  "Tanzania",
  "Tayikist\u00e1n",
  "Territorio Brit\u00e1nico del Oc\u00e9ano \u00cdndico",
  "Territorios Australes Franceses",
  "Territorios Palestinos",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad y Tobago",
  "T\u00fanez",
  "Turkmenist\u00e1n",
  "Turqu\u00eda",
  "Tuvalu",
  "Ucrania",
  "Uganda",
  "Uruguay",
  "Uzbekist\u00e1n",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Wallis y Futuna",
  "Yemen",
  "Yibuti",
  "Zambia",
  "Zimbabue"
]

const nationalities = [
  "AFGANA",             
  "ALBANESA",           
  "ALEMANA",            
  "ANDORRANA",          
  "ANGOLEÑA",           
  "ANTIGUANA",          
  "SAUDÍ",              
  "ARGELINA",           
  "ARGENTINA",          
  "ARMENIA",           
  "ARUBEÑA",           
  "AUSTRALIANA",       
  "AUSTRIACA",         
  "AZERBAIYANA",       
  "BAHAMEÑA",          
  "BANGLADESÍ",        
  "BARBADENSE",        
  "BAREINÍ",           
  "BELGA",             
  "BELICEÑA",          
  "BENINÉSA",          
  "BIELORRUSA",        
  "BIRMANA",           
  "BOLIVIANA",         
  "BOSNIA",            
  "BOTSUANA",          
  "BRASILEÑA",         
  "BRUNEANA",          
  "BÚLGARA",           
  "BURKINÉS",          
  "BURUNDÉSA",         
  "BUTANÉSA",          
  "CABOVERDIANA",      
  "CAMBOYANA",         
  "CAMERUNESA",        
  "CANADIENSE",        
  "CATARÍ",            
  "CHADIANA",          
  "CHILENA",           
  "CHINA",             
  "CHIPRIOTA",         
  "VATICANA",          
  "COLOMBIANA",        
  "COMORENSE",         
  "NORCOREANA",        
  "SURCOREANA",        
  "MARFILEÑA",         
  "COSTARRICENSE",     
  "CROATA",            
  "CUBANA",            
  "DANÉSA",            
  "DOMINIQUÉS",        
  "ECUATORIANA",       
  "EGIPCIA",           
  "SALVADOREÑA",       
  "EMIRATÍ",           
  "ERITREA",           
  "ESLOVACA",          
  "ESLOVENA",          
  "ESPAÑOLA",          
  "ESTADOUNIDENSE",    
  "ESTONIA",           
  "ETÍOPE",            
  "FILIPINA",          
  "FINLANDÉSA",        
  "FIYIANA",           
  "FRANCÉSA",          
  "GABONÉSA",          
  "GAMBIANA",          
  "GEORGIANA",         
  "GIBRALTAREÑA",      
  "GHANÉSA",           
  "GRANADINA",         
  "GRIEGA",            
  "GROENLANDÉSA",      
  "GUATEMALTECA",      
  "ECUATOGUINEANA",    
  "GUINEANA",          
  "GUINEANA",          
  "GUYANESA",          
  "HAITIANA",          
  "HONDUREÑA",         
  "HÚNGARA",           
  "HINDÚ",             
  "INDONESIA",         
  "IRAQUÍ",            
  "IRANÍ",             
  "IRLANDÉSA",         
  "ISLANDÉSA",         
  "COOKIANA",          
  "MARSHALÉSA",        
  "SALOMONENSE",       
  "ISRAELÍ",           
  "ITALIANA",          
  "JAMAIQUINA",        
  "JAPONÉSA",          
  "JORDANA",           
  "KAZAJA",           
  "KIRIBATIANA",      
  "KUWAITÍ",          
  "LAOSIANA",         
  "LESOTENSE",        
  "LETÓNA",           
  "LIBANÉSA",         
  "LIBERIANA",        
  "LIBIA",            
  "LIECHTENSTEINIANAL",
  "LITUANA",          
  "LUXEMBURGUÉSA",    
  "MALGACHE",         
  "MALASIA",          
  "MALAUÍ",           
  "MALDIVA",          
  "MALIENSE",         
  "MALTÉSA",          
  "MARROQUÍ",         
  "MARTINIQUÉS",      
  "MAURICIANA",       
  "MAURITANA",        
  "MEXICANA",         
  "MICRONESIA",       
  "MOLDAVA",          
  "MONEGASCA",        
  "MONGOLA",          
  "MONTENEGRINA",     
  "MOZAMBIQUEÑA",     
  "NAMIBIA",          
  "NAURUANA",         
  "NEPALÍ",           
  "NICARAGÜENSE",     
  "NIGERINA",         
  "NIGERIANA",        
  "NORUEGA",          
  "NEOZELANDÉSA",     
  "OMANÍ",            
  "NEERLANDÉSA",      
  "PAKISTANÍ",        
  "PALAUANA",         
  "PALESTINA",        
  "PANAMEÑA",         
  "PAPÚ",             
  "PARAGUAYA",        
  "PERUANA",          
  "POLACA",           
  "PORTUGUÉSA",       
  "PUERTORRIQUEÑA",   
  "BRITÁNICA",        
  "CENTROAFRICANA",   
  "CHECA",            
  "MACEDONIA",        
  "CONGOLEÑA",        
  "CONGOLEÑA",        
  "DOMINICANA",       
  "SUDAFRICANA",      
  "RUANDÉSA",         
  "RUMANA",           
  "RUSA",             
  "SAMOANA",          
  "CRISTOBALEÑA",     
  "SANMARINENSE",     
  "SANVICENTINA",     
  "SANTALUCENSE",     
  "SANTOTOMENSE",     
  "SENEGALÉSA",       
  "SERBIA",           
  "SEYCHELLENSE",     
  "SIERRALEONÉSA",    
  "SINGAPURENSE",     
  "SIRIA",            
  "SOMALÍ",           
  "CEILANÉSA",        
  "SUAZI",            
  "SURSUDANÉSA",      
  "SUDANÉSA",         
  "SUECA",            
  "SUIZA",            
  "SURINAMESA",       
  "TAILANDÉSA",       
  "TANZANA",          
  "TAYIKA",           
  "TIMORENSE",        
  "TOGOLÉSA",         
  "TONGANA",          
  "TRINITENSE",       
  "TUNECINA",         
  "TURCOMANA",        
  "TURCA",            
  "TUVALUANA",        
  "UCRANIANA",        
  "UGANDÉSA",         
  "URUGUAYA",         
  "UZBEKA",           
  "VANUATUENSE",      
  "VENEZOLANA",       
  "VIETNAMITA",       
  "YEMENÍ",           
  "ZAMBIANA",         
  "ZIMBABUENSE",      
]

export const styleConstants = {
  drawerWidth: 240,
  countries: countries,
  nationalities: nationalities,
} as const;