import { CircularProgress, Box } from "@mui/material";

interface LoadingProps {

}

const Loading = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <CircularProgress
        color="success"
        size={68}
      />
    </Box>
  );
}

export default Loading;