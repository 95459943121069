import { Button } from '@mui/material';
import React from 'react'
import ReactExport from "react-export-excel";
import ParamatersInterface from '../../interfaces/ParametersInterface';

interface Props {
  selectedData: any[],
  modulusData: any[],
  phaseData: any[],
  parametersData: ParamatersInterface[]
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const FileExcelExport = (props: Props) => {

  const renderSelectedData = () => {
    return props.selectedData.map((data, idx) => {
      return(     
        <ExcelSheet key={idx} data={data.data} name={`Fr Re Im ${data.name}`}>
            <ExcelColumn label="Frequency" value="fr"/>
            <ExcelColumn label="Real" value="x"/>
            <ExcelColumn label="Imaginary" value="y"/>
        </ExcelSheet>
      );
    });
  }

  const renderModulusData = () => {
    return props.modulusData.map((data, idx) => {
      return(     
        <ExcelSheet key={idx} data={data.data} name={`Fr Mod ${data.name}`}>
            <ExcelColumn  label="Frequency" value="x"/>
            <ExcelColumn label="Modulus" value="y"/>
        </ExcelSheet>
      );
    });
  }

  const renderPhaseData = () => {
    return props.phaseData.map((data, idx) => {
      return(     
        <ExcelSheet key={idx} data={data.data} name={`Fr Ph ${data.name}`}>
            <ExcelColumn label="Frequency" value="x"/>
            <ExcelColumn label="Phase" value="y"/>
        </ExcelSheet>
      );
    });
  }

  const renderParametersData = () => {
    //return props.parametersData.map((data, idx) => {
      return(     
        <ExcelSheet data={props.parametersData} name={`parameters`}>
            <ExcelColumn label="fc" value="fc"/>
            <ExcelColumn label="tau" value="tau"/>
            <ExcelColumn label="R0" value="Ro"/>
            <ExcelColumn label="Rinf" value="Rinf"/>
            <ExcelColumn label="alpha" value="alpha"/>
            <ExcelColumn label="x0" value="xo"/>
            <ExcelColumn label="y0" value="yo"/>
            <ExcelColumn label="r" value="radio"/>
        </ExcelSheet>
      );
    //});
  }

  return (
    <div>
      <ExcelFile element={
          <Button
            variant="contained"
            fullWidth 
            sx={{ mt: 3, mb: 2 }}
            disabled={(props.selectedData.length === 0 ? true : false)}
          >
            Exportar a Excel
          </Button>
        } fileName="DatosMediciones">
        {props.selectedData.length>0 && renderSelectedData()}
        {props.modulusData.length>0 && renderModulusData()}
        {props.phaseData.length>0 && renderPhaseData()}
        {props.parametersData.length>0 && renderParametersData()}
      </ExcelFile>
    </div>
  )
}

export default FileExcelExport;
