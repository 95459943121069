import { Avatar, Box, Button, Grid, Paper, TextField, Typography, Link, Container } from "@mui/material";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ErrorAlert from "../common/ErrorAlert";
import { useState } from "react";
import sendRequest from "../../services/api/sendRequest";
import { AxiosError, AxiosResponse } from "axios";
import { handleAxiosError } from "../../services/api/handleAxiosError";
import { trackPromise } from 'react-promise-tracker';

interface ChangePasswordTokenProps {
  handleCodeSubmit: () => void;
}
 
const ChangePasswordToken = (props: ChangePasswordTokenProps) => {

  const [error, setError] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const dataToSend = {
      email: data.get('email'),
    }

    trackPromise(
    sendRequest('/users/change_password_code', "CREATE", dataToSend)
      .then((response: AxiosResponse) => {
        props.handleCodeSubmit()
      })
      .catch((error: AxiosError) => {
        const errorObject = handleAxiosError(error)
        setError(errorObject.error)
      })
    );
  }

  return (  
    <Container component="main" maxWidth="xs">
			<Paper>
      <Box
        sx={{
          marginTop: 8,
          marginLeft: 8,
          marginRight: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOpenIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Cambiar Contraseña
        </Typography>
        {error && 
          <ErrorAlert
            error={error}
            handleClose={() => setError("") }
          />
        }
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
            />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Confirmar
          </Button>
        </Box>
      </Box>
      </Paper>
    </Container>
  );
}
 
export default ChangePasswordToken;