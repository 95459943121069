import { Box, Container, Grid, TextField, Typography, Button } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useState } from "react";
import ProjectInterface from "../../interfaces/Resources/ProjectInterface";
import { handleAxiosError } from "../../services/api/handleAxiosError";
import sendRequest from "../../services/api/sendRequest";
import ErrorAlert from "../common/ErrorAlert";
import Loading from "../common/Loading";
import { trackPromise } from 'react-promise-tracker';

interface ProjectPostFormProps {
  method: string
  handleProjectForm: (data) => void
}
 
const ProjectAddForm = (props: ProjectPostFormProps) => {

  const [error, setError] = useState("")

  const initialProjectState: ProjectInterface = {
    id: -1,
    title: "",
    description: "",
    role: "",
    created_at: "",
  };

  const [formData, setFormData] = useState(initialProjectState)

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    trackPromise(
    sendRequest('/projects', "CREATE", formData)
      .then((response: AxiosResponse) => {
        props.handleProjectForm(response.data)
      })
      .catch((error: AxiosError) => {
        const errorObject = handleAxiosError(error)
        setError(errorObject.error)
      })
    );  
  }

  return ( 
    <Container>
      {error && 
        <ErrorAlert
          error={error}
          handleClose={() => setError("") }
        />
      }
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name={"title"}
              value={formData.title}
              required
              fullWidth
              id="title"
              label="Título"
              onChange={handleInputChange}
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name={"description"}
              value={formData.description}
              required
              fullWidth
              id="description"
              label="Descripción"
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Confirmar
        </Button>
      </Box>
    </Container>
  );
}
 
export default ProjectAddForm;