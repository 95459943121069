import { Box, Grid, IconButton, Typography, Collapse } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AppFeature from '../components/common/AppFeature';
import { Link as Scroll } from 'react-scroll'

const StartPage = () => {

	const [checked, setChecked] = useState(false)
	const navigate = useNavigate()

	useEffect(() => {
		setChecked(true)
	}, [])

	return (
		<Box>
			{/* <Paper sx={{ width: '100%'}}> */}
			<Grid container spacing={1}>
			
				<Grid 
					item 
					container
					display="flex"
					justifyContent="center"
				>
					<Typography variant={"h1"} component={"h1"} sx={{ color: 'white '}}>
						<Box component="span" sx={{ color: 'secondary.main'}}>BIO</Box>
						ANALY
						<Box component="span" sx={{ color: 'secondary.main'}}>Z</Box>
						ER					
					</Typography>
				</Grid>

				
					<Grid 
						item 
						container 
						display="flex" 
						direction="column" 
						justifyContent="center" 
						textAlign='center' 
						sx={{ height: '100vh'}}
					>
						<Collapse in={checked} {...(checked ? { timeout: 1000 } : {})} collapsedSize={50}>
							<Box>
							<Typography variant={"h3"} component={"h2"} align="center" sx={{ color: 'white', fontSize: '4rem' }}>
								Aplicación multidispositivo para análisis de <br/>datos de bioimpedancia.
							</Typography>
							<Scroll to='app-features' smooth>
								<IconButton>
									<KeyboardArrowDownIcon sx={{ color: 'white', fontSize: '3rem' }}/>
								</IconButton>
							</Scroll>
							</Box>
						</Collapse>
					</Grid>
				

				<Grid item container direction="row" justifyContent="center" alignItems="flex-end">
					<AppFeature/>
				</Grid>
				
			</Grid>
			{/* </Paper> */}
		</Box>
	)
};
export default StartPage;