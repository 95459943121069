import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate, useLocation } from 'react-router-dom';
import sendRequest from '../services/api/sendRequest';
import { AxiosError, AxiosResponse } from 'axios';
import CookieService from '../services/CookieService';
import { handleAxiosError } from '../services/api/handleAxiosError';
import ErrorAlert from '../components/common/ErrorAlert';
import { Paper } from '@mui/material';
import { trackPromise } from 'react-promise-tracker';

interface LocationState {
  from: {
    pathname: string;
  };
}

const LoginPage = () => {

  const navigate = useNavigate()
  const location = useLocation();

  const { from } = location.state as LocationState || { from: { pathname: "/" } };

  //let from = location.state?.from?.pathname || "/"

  const [error, setError] = React.useState("")

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    
    const dataToSend = {
      email: data.get('email'),
      password: data.get('password'),
    }

    trackPromise(
    sendRequest('/users/login', "CREATE", dataToSend)
      .then((response: AxiosResponse) => {

        let date = new Date();
        date.setTime(date.getTime() + 60 * 24* 60 * 1000);
        const options = { path: "/", expires: date, sameSite: "strict" };

        CookieService.set("access_token", response.data.token, options);

        navigate(from, { replace: true })
      })
      .catch((error: AxiosError) => {
        const errorObject = handleAxiosError(error)
        setError(errorObject.error)
      })
    );
    
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper>
      <Box
        sx={{
          marginTop: 8,
          marginLeft: 8,
          marginRight: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Iniciar Sesión
        </Typography>
        {error && 
          <ErrorAlert
            error={error}
            handleClose={() => setError("") }
          />
        }
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Iniciar Sesión
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/change_password" variant="body2">
                Olvidaste la contraseña?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/register" variant="body2">
                {"No tienes una cuenta? Registrate."}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      </Paper>
    </Container>
  );
}
export default LoginPage;