import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import Typography from '@mui/material/Typography';
import { CardHeader, colors,} from '@mui/material';

interface GenericCardProps {
  title:string;
  children: React.ReactNode;
  action?: () => void;
}
 
const GenericCard = (props: GenericCardProps) => {
  return ( 
    <Card sx={{ minWidth: 275, height: '100%' }}>
      <CardHeader title={props.title} sx={{ color: 'white', backgroundColor: '#252525' }}/>

      <CardContent>
        {props.children}
      </CardContent>

      {/* Debería estar más parametrizado aún */}
      {props.action && 
        <CardActions>
          <Button 
            size="small" 
            startIcon={<LinearScaleIcon/>} 
            onClick={props.action}
          >
            Cambiar escala
          </Button>
        </CardActions>
      }
      
    </Card>
  );
}
 
export default GenericCard;