import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import PointInterface from '../../interfaces/PointInterface';
import ErrorAlert from '../common/ErrorAlert';
import GenericCard from '../common/GenericCard';
import { colors } from "./utils";

interface Props {
  title: string;
  error:string;
  data: any[];
  errorClose: () => void;
}

const CircumferenceChart = (props: Props) => {
  const [filesData, setFilesData] = useState([] as any[]);
  const [chartData, setChartData] = useState([] as any[]);
  const [axesType, setAxesType]   = useState("logarithmic");
  const chartRef = useRef<Line>(null);

  useEffect(() => {
    setFilesData(props.data);
  }, [ props.data ]);

  const getCircumferenceCoordenates = (circumference) => {
    const coordenates: PointInterface[] = [];
    let theta: number[] =  [];
    
    for (let i = 0; i < Math.PI; i = i+0.01) {
      theta.push(i);
    }

    theta.forEach((value, index) => {
      let point: PointInterface = {
        x: parseInt(circumference.radio)*Math.cos(value)+parseInt(circumference.xo),
        y: parseInt(circumference.radio)*Math.sin(value)+parseInt(circumference.yo),
      }

      coordenates.push(point)
    })

    return coordenates;
  } 

  useEffect(() => {
    let chartData: any[] = [];

    for (let i = 0; i < filesData.length; i++) {
      var chartObject = {
        label: filesData[i].name,
        data: getCircumferenceCoordenates(filesData[i].data),
        fill: false,
        backgroundColor: colors[i],
        borderColor: colors[i],
        key: i
      };
      chartData.push(chartObject);
    }
    setChartData(chartData);
  }, [ filesData ]);

  useEffect(() =>{
    if(chartRef.current !== null){
      chartRef.current.chartInstance.config.options.scales.xAxes[0].type = axesType;
      chartRef.current.chartInstance.update();
    }
  }, [axesType]);

  const toggleAxesType = () => {
    axesType === 'linear' ? setAxesType('logarithmic') : setAxesType('linear')
  }

  return (
    <Box>
      {props.error && 
        <ErrorAlert
          error={props.error}
          handleClose={ () => props.errorClose() }
        />
      }
      <GenericCard
        title={props.title}
        action={toggleAxesType}
      >
        <div className="chart">
        {chartData.length>0 && 
        <Line
          // redraw
          type={"scatter"}
          ref={chartRef}
          data={{            
              datasets: chartData
            }}
            options={{
              scales: {
                xAxes: [{
                  type: 'logarithmic',
                  position: 'bottom'
                }],
                yAxes: [{
                  type: 'linear',
                  position: 'left'
                }]
              },
              maintanAspectRatio: false,
            }}
        />}
        </div>
      </GenericCard>
    </Box>
  );
};
export default CircumferenceChart;