import React from "react";
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ActivationPage = () => {

	const navigate = useNavigate()

	const navigateToLearn = () => {
		navigate('/aprendizaje')
	} 

	return (
		<Box>
		<Grid container spacing={1}>
			<Grid 
				item 
				container
				display="flex"
				justifyContent="center"
			>
				<Typography variant={"h1"} component={"h1"} >
					<Box component="span" sx={{ color: 'secondary.main'}}>BIO</Box>
					ANALY
					<Box component="span" sx={{ color: 'secondary.main'}}>Z</Box>
					ER					
				</Typography>
				
			</Grid>

			<Grid item container display="flex" direction="column" justifyContent="center">
			<Typography variant={"h3"} component={"h2"} align="center" color='white'>
					Su cuenta se ha creado. Se envió un correo a su email para la activación.
				</Typography>
			</Grid>

			<Grid item container direction="row" justifyContent="center" alignItems="flex-end">
				<Button
					type="submit"
					variant="contained"
					size="large"
					color="secondary"
					sx={{ mt: 3, mb: 2 }}
					onClick={navigateToLearn}
				>
					Login
				</Button>
			</Grid>

		</Grid>
	</Box>
	);
}

export default ActivationPage;