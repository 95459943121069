import React from 'react'
import ParameterChip from './ParameterChip'
import Stack from '@mui/material/Stack';
import ParametersInterface from '../../interfaces/ParametersInterface';
import Chip from '@mui/material/Chip';
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';

interface Props {
  data: ParametersInterface
  circumference: boolean
}

export default function ChipRow(props: Props) {

  const listItems = [
    {
      icon: <FaceIcon />,
      title: "fc",
      value: props.data.fc
    },
    {
      icon: <FaceIcon />,
      title: "τ",
      value: props.data.tau
    },
    {
      icon: <FaceIcon />,
      title: "R0",
      value: props.data.Ro
    },
    {
      icon: <FaceIcon />,
      title: "RInf",
      value: props.data.Rinf
    },
    {
      icon: <FaceIcon />,
      title: "α",
      value: props.data.alpha
    },
  ]

  const listItemsCircumference = [
    {
      icon: <FaceIcon />,
      title: "xo",
      value: props.data.xo
    },
    {
      icon: <FaceIcon />,
      title: "yo",
      value: props.data.yo
    },
    {
      icon: <FaceIcon />,
      title: "radio",
      value: props.data.radio
    },
  ]

  return (
    <Box>

    {props.circumference ? (
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
  alignItems="center"
        sx={{
          overflowX: "scroll",// added scroll
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        {listItemsCircumference.map((item, index) => {
          return (
            <Chip variant="outlined" key={index} color="info" label={`${item.title}: ${item.value}`} />
          )
        })}
      </Stack>
    ) : (
      <Stack
        direction="row"
        spacing={2}
        sx={{
          overflowX: "scroll",// added scroll
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        {listItems.map((item, index) => {
          return (
            <Chip variant="outlined" key={index} color="info" label={`${item.title}: ${item.value}`} />
          )
        })}
      </Stack>
    )}
  </Box>
    // <Box
    //   sx={{
    //     height: 215,
    //     overflow: "scroll",
    //     overflowY: "scroll",// added scroll
    //     display: 'flex',
    //     flexDirection: 'column'
    //   }}
    // >
    //   <List>
    //     {listItems.map((item, index) => {
    //       return(
    //         <ListItem key={index}>
    //           <ListItemIcon>
    //             {item.icon}
    //           </ListItemIcon>
    //           <ListItemText
    //             primary={`${item.title}:`}
    //             secondary={`${item.value}`}
    //           />
    //         </ListItem>
    //       )
    //     })}  
    //   </List>
    // </Box>

    // <Stack 
    //   direction="row" 
    //   spacing={1} 
    //   sx={{ 
    //     overflowX: "scroll",// added scroll
    //     display: 'flex',
    //     flexDirection: 'row'
    //   }}
    // >
    //   <ParameterChip
    //     title="fc"
    //     value={props.data.fc}
    //   />
    //   <ParameterChip
    //     title="tau"
    //     value={props.data.tau}
    //   />
    //   <ParameterChip
    //     title="R0"
    //     value={props.data.Ro}
    //   />
    //   <ParameterChip
    //     title="Rinf"
    //     value={props.data.Rinf}
    //   />
    //   <ParameterChip
    //      title="alpha"
    //      value={props.data.alpha}
    //   />
    //   <ParameterChip
    //     title="xo"
    //     value={props.data.xo}
    //   />
    //   <ParameterChip
    //     title="yo"
    //     value={props.data.yo}
    //   />
    //   <ParameterChip
    //     title="radio"
    //     value={props.data.radio}
    //   />
    // </Stack>
  )
}
