import { Box, Container, Button } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useState } from "react";
import { handleAxiosError } from "../../services/api/handleAxiosError";
import sendRequest from "../../services/api/sendRequest";
import ErrorAlert from "../common/ErrorAlert";
import { trackPromise } from 'react-promise-tracker';

interface ProjectEditFormProps {
  project_id: number
  method: string
  handleProjectForm: (data) => void
}
 
const ProjectEditForm = (props: ProjectEditFormProps) => {

  const [error, setError] = useState("")

  const handleSubmit = (e) => {
    e.preventDefault()

    trackPromise(
    sendRequest(`/projects/${props.project_id}`, props.method)
      .then((response: AxiosResponse) => {
        props.handleProjectForm(response.data)
      })
      .catch((error: AxiosError) => {
        const errorObject = handleAxiosError(error)
        setError(errorObject.error)
      })
    );
    
  }

  return ( 
    <Container>
      {(error) && 
        <ErrorAlert
          error={error}
          handleClose={() => setError("") }
        />
      }
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="warning"
          sx={{ mt: 3, mb: 2 }}
        >
          Confirmar
        </Button>
      </Box>
    </Container>
  );
}
 
export default ProjectEditForm;