import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import ProjectInterface from "../interfaces/Resources/ProjectInterface";
import ProjectEditForm from "../components/projects/ProjectEditForm";
import ProjectDeleteForm from "../components/projects/ProjectDeleteForm";
import ProjectTable from "../components/tables/ProjectTable";
import ErrorAlert from "../components/common/ErrorAlert";
import ProjectPostForm from "../components/projects/ProjectAddForm";
import GenericDialog from "../components/modals/GenericDialog";
import SuccessAlert from "../components/common/SuccessAlert";
import CollaboratorForm from "../components/projects/CollaboratorForm";
import sendRequest from "../services/api/sendRequest";
import { AxiosError, AxiosResponse } from "axios";
import { handleAxiosError } from "../services/api/handleAxiosError";
import { trackPromise } from 'react-promise-tracker';

const ProjectList = () => {

  // Para los modales
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCollaboratorModal, setShowCollaboratorModal] = useState(false);
  const [success, setSuccess] = useState(false)

  // Para manejar los proyectos
  const [projects, setProjects] = useState([] as ProjectInterface[]);
  const [targetProject, setTargetProject] = useState(-1);

  // Para el estado del componente
  const [error, setError] = useState("")

  useEffect(() => {

    trackPromise(
      sendRequest('/projects', "GET")
        .then((response: AxiosResponse) => {
          setProjects(response.data)
        })
        .catch((error: AxiosError) => {
          const errorObject = handleAxiosError(error)
          setError(errorObject.error)
        })
    );

  }, [])

  const handleProjectAddForm = async (data) => {
    /* Crea el proyecto a nivel base de datos y actualiza la vista */
    const project = data;

    let newProjects: ProjectInterface[];
    newProjects = projects;

    newProjects = [...projects, project]

    setProjects(newProjects);

    setShowAddModal(!showAddModal);
    setSuccess(true)
  };

  const handleProjectEditForm = async (data) => {
    const newProject = data;

    let newProjects: ProjectInterface[];
    newProjects = projects

    newProjects = [...projects];

    const isEditedProject = (project: ProjectInterface) => project.id === targetProject;

    let index: number = newProjects.findIndex(isEditedProject);
    newProjects[index] = newProject;

    setProjects(newProjects);
    setShowEditModal(!showEditModal);
    setSuccess(true)
  };

  const handleProjectDeleteForm = (data) => {
    let newProjects: ProjectInterface[];

    newProjects = projects.filter((project: ProjectInterface) => {
      return project.id !== targetProject;
    });

    setProjects(newProjects);

    setShowDeleteModal(!showDeleteModal);
    setShowCollaboratorModal(false)
    setSuccess(true)
  };

  const handleProjectCollaboratorForm = () => {
    setSuccess(true)
  }

  const handleOnEdit = (project_id: number) => {
    setTargetProject(project_id)
    setShowEditModal(true)
  }

  const handleOnDelete = (project_id: number) => {
    setTargetProject(project_id)
    setShowDeleteModal(true)
  }

  const handleAddOpen = () => {
    setShowAddModal(true)
  }

  const handleAddClose = () => {
    setShowAddModal(false)
  }

  const handleCollaboratorOpen = (project_id: number) => {
    setTargetProject(project_id)
    setShowCollaboratorModal(true)
  }

  const handleSuccessClose = () => {
    setSuccess(false)
  }

  return (
    <Box>
      {error &&
        <ErrorAlert
          error={error}
          handleClose={ () => setError("") }
        />
      }
      <Box>
        {success && (
          <SuccessAlert
            show={success}
            handleClose={handleSuccessClose}
          />
        )}

        <ProjectTable
          projects={projects}
          onClickAdd={handleAddOpen}
          onClickCollab={(id) => handleCollaboratorOpen(id)}
          onClickDelete={(id) => handleOnDelete(id)}
          onClickEdit={(id) => handleOnEdit(id)}
        />

      </Box>
      <GenericDialog
        show={showAddModal}
        title={"Agregar Proyecto"}
        handleClose={handleAddClose}
      >
        <Box>
          <ProjectPostForm
            method="CREATE"
            handleProjectForm={(data) => handleProjectAddForm(data)}
          />
        </Box>
      </GenericDialog>

      <GenericDialog
        show={showEditModal}
        title={"Editar Proyecto"}
        handleClose={() => setShowEditModal(!showEditModal)}
      >
        <Box>
          <ProjectEditForm
            project_id={targetProject}
            method="UPDATE"
            handleProjectForm={(data) => handleProjectEditForm(data)}
          />
        </Box>
      </GenericDialog>

      <GenericDialog
        show={showDeleteModal}
        title={"Eliminar Proyecto"}
        handleClose={() => setShowDeleteModal(!showDeleteModal)}
      >
        <Box>
          <ProjectDeleteForm
            project_id={targetProject}
            method="DELETE"
            handleProjectForm={(data) => handleProjectDeleteForm(data)}
          />
        </Box>
      </GenericDialog>

      <GenericDialog
        show={showCollaboratorModal}
        title={"Agregar Colaborador"}
        handleClose={() => setShowCollaboratorModal(!showCollaboratorModal)}
      >
        <Box>
          <CollaboratorForm
            project_id={targetProject}
            handleProjectForm={handleProjectCollaboratorForm}
          />
        </Box>
      </GenericDialog>

    </Box>
  );
};
export default ProjectList;