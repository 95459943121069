import { Avatar, Box, Card, CardContent, CardHeader, Collapse, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";

interface AvatarCardProps {
  title: string;
  subheader: string;
  avatarUrl: string;
  description: string[];
}

const AvatarCard = (props: AvatarCardProps) => {

  const [checked, setChecked] = useState(false)

  useEffect(() => {
    setChecked(true)
  }, [])

  return (
    <Box>
      <Collapse in={checked} {...(checked ? { timeout: 1000 } : {})} collapsedSize={50}>
        <Card
          sx={{
            maxWidth: 645,
            background: 'rgba(0,0,0,0.5)',
            margin: '20px', boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
          }} 
          variant='outlined'
        >
          <CardHeader
            title={
              <Typography sx={{ fontSize: '2rem' }}> {props.title} </Typography>
            }
            subheader={
              <Typography sx={{ fontSize: '1rem' }}> {props.subheader} </Typography>
            }
            avatar={
              <Avatar sx={{ m: 1, width: 120, height: 120 }} src={process.env.PUBLIC_URL + props.avatarUrl} />
            }
            sx={{ color: 'white' }}
          />
          <CardContent>
            <Typography variant="body2" align="justify" color='white'>
              {props.description.length > 0 && props.description.map(paragraph => {
                return (
                  <div>
                    {paragraph}
                    <br />
                  </div>
                )
              })
              }
            </Typography>
          </CardContent>
        </Card>
      </Collapse>
    </Box>
  );
}

export default AvatarCard;