import { Avatar, Box, Button, Grid, Paper, TextField, Typography, Link, Container } from "@mui/material";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorAlert from "../common/ErrorAlert";
import sendRequest from "../../services/api/sendRequest";
import { AxiosError, AxiosResponse } from "axios";
import { handleAxiosError } from "../../services/api/handleAxiosError";
import ChangePasswordToken from "./ChangePasswordToken";
import { trackPromise } from 'react-promise-tracker';

const ChangePasswordForm = () => {

	const [error, setError] = useState("");
	const [codeSent, setCodeSent] = useState(false);
	const navigate = useNavigate();

	const handleCodeSubmit = () => {
		setCodeSent(true);
	}

	const handleSubmit = async (event) => {
		event.preventDefault();
    const data = new FormData(event.currentTarget);

    const dataToSend = {
      token: data.get('token'),
      password: data.get('password'),
      password_confirmation: data.get('password_confirmation'),
    }

		trackPromise(
    sendRequest('/users/change_password', "CREATE", dataToSend)
      .then((response: AxiosResponse) => {
        navigate('/')
      })
      .catch((error: AxiosError) => {
        const errorObject = handleAxiosError(error)
        setError(errorObject.error)
      })
		);
	}

	return (
		<Box>
			{!codeSent ? (
				<ChangePasswordToken
					handleCodeSubmit={handleCodeSubmit}
				/>
			) : (
				<Container component="main" maxWidth="xs">
				<Paper 
					sx={{
						width: '100%',
					}}>
				<Box
					sx={{
						marginTop: 8,
						marginLeft: 8,
						marginRight: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
						<LockOpenIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Registrarse
					</Typography>
					{error && 
						<ErrorAlert
							error={error}
							handleClose={ () => setError("") }
						/>
					}
					<Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									name="token"
									label="Código"
									type="token"
									id="token"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									name="password"
									label="Contraseña"
									type="password"
									id="password"
									autoComplete="new-password"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									fullWidth
									name="password_confirmation"
									label="Confirmar Contraseña"
									type="password"
									id="password_confirmation"
									autoComplete="new-password"
								/>
							</Grid>
						</Grid>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
						>
							Registro
						</Button>
						<Grid container justifyContent="flex-end">
							<Grid item>
								<Link href="/login" variant="body2">
									Ya tienes una cuenta? Inicia sesión
								</Link>
							</Grid>
						</Grid>
					</Box>
				</Box>
				</Paper>
				</Container>
			)}
		</Box>
	)
}

export default ChangePasswordForm;