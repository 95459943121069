import ResourceInterface from '../../interfaces/ResourceInterface';
import createAxios from '../HttpService';

const sendRequest = (url = '', method: string, data?: ResourceInterface) => {

  // Lo creo cada vez por la cookie access
  const http = createAxios()

  const httpMethod = {
    "GET":    http.get,
    "CREATE": http.post, 
    "UPDATE": http.put,
    "DELETE": http.delete,
  }

  const verb = httpMethod[method]
  return data ? verb(url, data) : verb(url)

}

export default sendRequest;