import { AppBar, Box, Button, Divider, Toolbar, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import { styleConstants } from '../../services/Constants';
import { useAuth } from '../../services/custom_hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import sendRequest from '../../services/api/sendRequest';
import { AxiosError, AxiosResponse } from 'axios';
import CookieService from '../../services/CookieService';
import { handleAxiosError } from '../../services/api/handleAxiosError';
import { Theme } from '@mui/material/styles'
 
interface NavBarProps {
  open: boolean
  handleOpen: () => void
}

const NavBar = (props: NavBarProps) => {

  const auth = useAuth()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToProfile = () => {
    navigate('/profile')
    handleClose()
  }

  const navigateToLoginPage = () => {
    navigate('/login')
  }

  const navigateToRegisterPage = () => {
    navigate('/register')
  }

  const handleLogout = () => {

    sendRequest('/users/logout', "CREATE")
      .then((response: AxiosResponse) => {
        CookieService.remove("access_token");
        handleClose()
        navigate('/')
      })
      .catch((error: AxiosError) => {
        const errorObject = handleAxiosError(error)
      })

    handleClose()
  }

  return ( 
    <Box sx={{ flexGrow: 1 }}>
    <AppBar 
      position="fixed"
      sx={(theme: Theme) => ({ 
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(props.open && {
          width: `calc(100% - ${styleConstants.drawerWidth}px)`, 
          ml: `${styleConstants.drawerWidth}px`,
          transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
        background: 'none',       
      })}
    >
      <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleOpen}
            edge="start"
            sx={{ mr: 2, ...(props.open && { display: 'none' }) }}
          >
          <MenuIcon />
        </IconButton>

          <Typography variant="h6" component="p" align="left" sx={{ flexGrow: 1}}>
            <Box component="span" sx={{ color: 'secondary.main'}}>BIO</Box>
						ANALY
						<Box component="span" sx={{ color: 'secondary.main'}}>Z</Box>
						ER
          </Typography>

        {auth ? (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={ navigateToProfile }>Perfil</MenuItem>
                <MenuItem onClick={ handleLogout }>Salir</MenuItem>
              </Menu>
            </div>
          ) : (
            <div>
              <Button color="secondary" variant="outlined" onClick={ navigateToRegisterPage }>
                Registrarse
              </Button>
              <Button color="secondary" variant="outlined" onClick={ navigateToLoginPage }>
                Iniciar Sesión
              </Button>
            </div>
          )}
      </Toolbar>
    </AppBar>
  </Box>
  );
}
 
export default NavBar;