import React, { useEffect, useState } from 'react'
import ProfileInterface from '../../interfaces/Resources/ProfileInterface';
import { Box, Button, Grid, MenuItem, TextField } from '@mui/material';
import Input from '@mui/material/Input';
import ErrorAlert from '../common/ErrorAlert';
import Loading from '../common/Loading';
import sendRequest from '../../services/api/sendRequest';
import { AxiosError, AxiosResponse } from 'axios';
import { handleAxiosError } from "../../services/api/handleAxiosError";
import InputLabel from '@mui/material/InputLabel';
import { styleConstants } from '../../services/Constants';

interface Props {
	profile: ProfileInterface;
	handleProfileForm: (data) => void;
}

const ProfileForm = (props:Props) => {
  const initialProfileState: ProfileInterface = {
    id: -1,
    name: "",
    last_name: "",
    institution: "",
    function: "",
    nationality: "",
    country: "",
    birth_date: "",
  };

  const [formData, setFormData] = useState(initialProfileState);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const data: ProfileInterface = {
      id: props.profile.id,
      name: props.profile.name,
      last_name: props.profile.last_name,
      institution: props.profile.institution,
      function: props.profile.function,
      nationality: props.profile.nationality,
      country: props.profile.country,
      birth_date: props.profile.birth_date,
    };
    
    if(data.id !== undefined) {
      setFormData(data);
    }
    return () => {
      setError("");
    }
  }, [ props.profile ])

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  };

  const handleSubmit = (event) => {
    event.preventDefault()
    setLoading(true)

    sendRequest(`/users/profile/${formData.id}`, "UPDATE", formData)
      .then((response: AxiosResponse) => {
        props.handleProfileForm(response.data)
      })
      .catch((error: AxiosError) => {
        const errorObject = handleAxiosError(error)
        setError(errorObject.error)
      })

    setLoading(false)
  }

  return(
  <div>
    <Box>
      {error && 
        <ErrorAlert
          error={error}
          handleClose={() => setError("") }
        />
      }
      {loading && (
        <Loading/>
      )}
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3, height: '100%' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextField
              name={"name"}
              value={formData.name}
              fullWidth
              id="name"
              label="Nombre"
              onChange={handleInputChange}
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              name={"last_name"}
              value={formData.last_name}
              fullWidth
              id="last_name"
              label="Apellido"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              name={"institution"}
              value={formData.institution}
              fullWidth
              id="institution"
              label="Institución"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              name={"function"}
              value={formData.function}
              fullWidth
              id="function"
              label="Función"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              name={"country"}
              value={formData.country}
              fullWidth
              select
              id="country"
              label="País de residencia"
              onChange={handleInputChange}
            >
              {styleConstants.countries.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField> 
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              name={"nationality"}
              value={formData.nationality}
              fullWidth
              select
              id="nationality"
              label="Nacionalidad"
              onChange={handleInputChange}
            >
              {styleConstants.nationalities.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12}>
            <InputLabel htmlFor="birth_date">Fecha de Nacimiento</InputLabel>
              <Input
                // className="form-control"
                type="date"
                value={formData.birth_date}
                onChange={event => handleInputChange(event)}
                name={"birth_date"}
                id="birth_date"
              />
            
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Editar Perfil
        </Button>
      </Box>
    </Box>
  </div>
  );
};
export default ProfileForm;