import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';

interface CardImageProps {
  title: string;
  description: string;
  imageUrl: string;
  checked: boolean;
}

export default function MediaCard(props: CardImageProps) {

  return (
    <Collapse in={props.checked} {...(props.checked ? { timeout: 1000 } : {})}>
      <Card sx={{ maxWidth: 645, background: 'rgba(0,0,0,0.5)', margin: '20px' }} variant='outlined'>
        <CardMedia
          component="img"
          height="440"
          image={props.imageUrl}
          alt="feat-prop"
        />
        <CardContent>
          <Typography 
            gutterBottom 
            variant="h5" 
            component="div" 
            color='white' 
            sx={{ fontWeight: 'bold', fontSize: '2rem', }}
          >
            {props.title}
          </Typography>
          <Typography 
            variant="body2" 
            color="#ddd"
            sx={{ fontSize: '1.1rem', }}
          >
            {props.description}
          </Typography>
        </CardContent>
      </Card>
    </Collapse>
  );
}