import { Box, Grid, IconButton, Input, Button} from "@mui/material";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Papa from "papaparse";
import { useEffect, useState } from "react";
import GenericCard from "../common/GenericCard";
import DataTableComponent from "./DataTable";

interface MeasurerTableProps {
  reboot: boolean;
  handleFileSubmit: (delimiter) => void;
}
 
const MeasurerFile = (props: MeasurerTableProps) => {

  const [parsedCsvData, setParsedCsvData] = useState<any>([]);
  const [parsedCsvErrors, setParsedCsvErrors] = useState<any>([]);
  const [parsedCsvMeta, setParsedCsvMeta] = useState<any>([]);
  const [file, setFile] = useState<File>({} as File)

  const handleInputChange = (event) => {
    
    const files = event.target.files;
    
    let file: File = files[0]

    setFile(file);
  };

  useEffect(() => {
    if(props.reboot) {
      setParsedCsvData([])
      setParsedCsvErrors([])
      setParsedCsvMeta([])
      setFile({} as File)      
    }
  }, [props.reboot])
  
  const parseFile = file => {

    Papa.parse(file, {
      header: false,
      complete: results => {
        setParsedCsvData(results.data)
        setParsedCsvErrors(results.errors)
        setParsedCsvMeta(results.meta)
        props.handleFileSubmit(results.meta.delimiter)
      },
    });
  };

  return (  
    <Box>
      <GenericCard
        title="Nuevo Medidor"
      >
        <Grid container spacing={2}>   
          <Grid item xs={12} sm={12} md={6}>
              <Input
                // className="custom-file-Input"
                type="file"
                id="measurement"
                name="measurement"
                placeholder="Archivo"
                inputProps={{ accept: '.csv' }}
                onChange={ handleInputChange }
              ></Input>
              <label htmlFor="measurement">
                Cargar Archivo...
              </label>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Button
                disabled={file.name ? false : true}
                fullWidth
                variant="contained"
                onClick={ () => parseFile(file) }
              >
                Ver archivo
              </Button>
          </Grid>
          <Grid item xs>
            {parsedCsvData.length > 0 &&
              <DataTableComponent
                title={ file.name }
                selectable={false}
                data={parsedCsvData}
              />
            }
            
          </Grid>
        </Grid>
      </GenericCard>
    </Box>
  );
}
 
export default MeasurerFile;