import { Box, Button, Container, Grid, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import MeasurerInterface from "../../interfaces/MeasurerInterface";
import { handleAxiosError } from "../../services/api/handleAxiosError";
import sendRequest from "../../services/api/sendRequest";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ErrorAlert from "../common/ErrorAlert";
import { trackPromise } from 'react-promise-tracker';

interface Props {
  projectId: number;
  itemId: number;
  handleAddFiles: (data) => void;
  modal: boolean;
}

const FilesAddForm = (props: Props) => {
  const [files, setFiles] = useState([] as File[]);
  const [measurers, setMeasurers] = useState([] as MeasurerInterface[]);
  const [measurer, setMeasurer] = useState('');
  const [error, setError] = useState("")

  useEffect(() => {

    trackPromise(
    sendRequest('/measurers', "GET")
      .then((response: AxiosResponse) => {
        setMeasurers(response.data);
      })
      .catch((error: AxiosError) => {
        const errorObject = handleAxiosError(error)
        setError(errorObject.error)
      })
    );


  }, [ props.modal ]);

  const handleInputChange = (event) => {
    
    const files = event.target.files;
    let newFiles: File[] = [];
    for (var i = 0; i < files.length; i++) {
      newFiles.push(files[i]);
    }
    
    setFiles(newFiles);
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const measurer = event.target.value as string;
    setMeasurer(measurer);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();

    data.append("measurer", measurer);

    for (var i = 0; i < files.length; i++) {
      data.append("measurements", files[i]);
    }

    trackPromise(
    sendRequest(`/projects/${props.projectId}/dataset/${props.itemId}/measurements`, "CREATE", data)
      .then((response: AxiosResponse) => {
        props.handleAddFiles(response.data);
      })
      .catch((error: AxiosError) => {
        const errorObject = handleAxiosError(error)
        setError(errorObject.error)
      })
    );  

    setFiles([]);
    setMeasurer("");
  };

  return (
    <Container>
      {error && 
        <ErrorAlert
          error={error}
          handleClose={() => setError("") }
        />
      }
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
        {/* <FormControl fullWidth> */}
          <Grid container spacing={2}>
            {/* Select de medidores */}
            <Grid item xs={12} sm={12}>
                <InputLabel id="select-measurer">Medidor</InputLabel>
                <Select
                  fullWidth
                  labelId="select-measurer"
                  id="select-measurer-id"
                  value={measurer}
                  label="Medidor"
                  onChange={handleSelectChange}
                >
                  {measurers.length > 0 && measurers.map((measurer, index) => {
                    return (
                      <MenuItem key={index} value={measurer.name}>{measurer.name}</MenuItem>
                    )
                  })}
                </Select>
            </Grid>
            {/* file input básico */}
            <Grid item xs={12} sm={12}>
              <input
                disabled={measurer === "" ? true: false}
                // className="custom-file-input"
                type="file"
                id="measurement"
                name="measurement"
                multiple
                onChange={ handleInputChange }
              ></input>
              <label htmlFor="measurement">
                Cargar Archivos...
              </label>
            </Grid>
            {/* lista de archivos A subir */}
            <Grid item xs={12} sm={12}>
              <List>
                {files.length > 0 && files.map((file, index) => {
                  return (
                    <ListItem disablePadding key={index}>
                      <ListItemIcon>
                        <AttachFileIcon/>
                      </ListItemIcon>
                      <ListItemButton>
                        <ListItemText primary={file.name} />
                      </ListItemButton>
                    </ListItem>
                  )
                })}
              </List>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={(files.length === 0 ? true: false)}
            startIcon={ <FileUploadIcon/>}
            sx={{ mt: 3, mb: 2 }}
          >
            Confirmar
          </Button>
        {/* </FormControl> */}
      </Box>
    </Container>
  );
};
export default FilesAddForm;
