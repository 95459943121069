import { Box, Grid, Paper } from '@mui/material';
import React, { useState } from 'react';
import SuccessAlert from '../components/common/SuccessAlert';
import MeasurerFile from '../components/measurer/MeasurerFile';
import MeasurerForm from '../components/measurer/MeasurerForm';

const MeasurerPage = () => {

  const [delimiter, setDelimiter] = useState("")
  const [reboot, setReboot] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleFileSubmit = (delimiter: string) => {
    setDelimiter(delimiter)
  }

  const handleFormSubmit = () => {
    setReboot(true)
    setSuccess(true)
  }

  const handleSuccessClose = () => {
    setSuccess(false)
  }

  return (
    <Box>
      {success && ( 
        <SuccessAlert
          show={ success }
          handleClose={ handleSuccessClose }
        /> 
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <Paper sx={{ height: '100%' }}>
            <MeasurerFile
              reboot={ reboot }
              handleFileSubmit={ handleFileSubmit }
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Paper sx={{ height: '100%' }}>
            <MeasurerForm
              handleFormSubmit={ handleFormSubmit }
              delimiter={ delimiter }
            />
          </Paper>
        </Grid> 
      </Grid>
    </Box>
    );
}

export default MeasurerPage;