import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DataSetInterface from '../../interfaces/Resources/DataSetInterface';
import { Box } from '@mui/material';

interface DataSetListInterface {
  datasets: DataSetInterface[];
  onSelect: (id) => void
  onClickDelete: (id) => void;
  onClickEdit: (id) => void;
}

interface Selected {
  title: string;
  id: number;
  selected: boolean;
}

const DataSetList = (props: DataSetListInterface) => {

  const [selected, setSelected] = React.useState<Selected[]>([]);
  const [selectedDataSetId, setSelectedDataSetId] = React.useState(-1)

  React.useEffect(() => {

    const newDataSets = props.datasets.map(function(project){
      let obj: Selected = {} as Selected

      obj.title = project.title
      obj.id = project.id

      if(project.id === selectedDataSetId)
        obj.selected = true
      else 
        obj.selected = false
      
      return obj
    })

    setSelected(newDataSets)
  }, [ props.datasets ])

  const handleToggle = (idToSelect: number) => () => {
    const selectedIndex = selected.map(function(e) { return e.selected }).indexOf(true)
    const toSelectIndex = selected.map(function(e) { return e.id }).indexOf(idToSelect)

    const toSelectDataSet: Selected = selected[toSelectIndex]

    if(selectedIndex !== -1) {
      const selectedDataSet: Selected = selected[selectedIndex]

      if(selectedDataSet.id !== idToSelect){
        toSelectDataSet.selected = true
        setSelectedDataSetId(toSelectDataSet.id)
        props.onSelect(toSelectDataSet.id)
      } else {
        setSelectedDataSetId(-1)
        props.onSelect(-1)
      }

      selectedDataSet.selected = false
    } else {
      toSelectDataSet.selected = true
      setSelectedDataSetId(toSelectDataSet.id)
      props.onSelect(toSelectDataSet.id)
    }
  };

  const isSelected = (id: number) => {
    const selectedIndex = selected.map(function(e) { return e.id }).indexOf(id)
    if(selectedIndex !== -1){
      return selected[selectedIndex].selected
    } else {
      return false
    }
  }

  return (
    <Box 
      sx={{
        height: 215,
        overflow: "scroll",
        overflowY: "scroll",// added scroll
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <List>
        {props.datasets.map((dataset, index) => {
          const isItemSelected = isSelected(dataset.id);
          const labelId = `checkbox-list-label-${index}`;

          return (
            <ListItem
              key={index}
              secondaryAction={
                <Box>
                  <span>
                  <IconButton 
                    edge="end" 
                    aria-label="edit"  
                    onClick={ (id) => props.onClickEdit(dataset.id) }
                    disabled={isItemSelected ? false : true}
                  >
                    <EditIcon />
                  </IconButton>
                  </span>
                  <span>
                  <IconButton 
                    edge="end" 
                    aria-label="delete" 
                    onClick={ (id) => props.onClickDelete(dataset.id) }
                    disabled={isItemSelected ? false : true}
                  >
                    <DeleteIcon />
                  </IconButton>
                  </span>
                </Box>
              }
              disablePadding
            >
              <ListItemButton role={undefined} onClick={handleToggle(dataset.id)} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={ isItemSelected }
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${dataset.title}`} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}
export default DataSetList;