import { Box, Container } from '@mui/material';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import SelectedDataInterface from '../../interfaces/SelectedDataInterface';
import ErrorAlert from '../common/ErrorAlert';
import GenericCard from '../common/GenericCard';
import Loading from '../common/Loading';
import { colors } from "./utils";

interface Props {
  title: string;
  data: any[];
  selectedData: SelectedDataInterface;
  error: string;
  errorClose: () => void;
}

const LineChart = (props: Props) => {
  const [filesData, setFilesData] = useState([] as any[]);
  const [chartData, setChartData] = useState([] as any[]);
  const [reversed, setreversed]   = useState(false);
  const originalData = props.data;
  const [axesType, setAxesType]   = useState("linear");
  const chartRef = useRef<Line>(null);

  useEffect(() => {
    setFilesData(props.data);

    return () => {
      setFilesData([]);
    }
  }, [ props.data ]);

  useEffect(() => {
    if(props.selectedData.selectedData !== null && props.selectedData.selectedData.length > 0){
      let newData;
      // let fillData: any[] = [];
      let frequencies = new Set(props.selectedData.selectedData.map(({fr}) => fr));

      for(let i = 0; i < originalData.length; i++) {

        if(originalData[i].name === props.selectedData.name) {

          // Filtrar los datos por frecuencias seleccionadas
          newData = [...originalData[i].data.filter((obj) => {   
            if(frequencies.has(obj.x) || frequencies.has(obj.fr)) {
              return true;
            }
            else {
              return false;
            }
          })];

        }

        if(chartRef.current !== null){
          chartRef.current.chartInstance.data.datasets.forEach((dataset) => {   
            if(dataset.label === props.selectedData.name){
              dataset.data = (newData);
            }
          })
          chartRef.current.chartInstance.update();
        }
      }
    }
  }, [ props.selectedData, originalData ]);

  useEffect(() => {
    let chartData: any[] = [];
    for (let i = 0; i < filesData.length; i++) {
      var chartObject = {
        label: filesData[i].name,
        data: filesData[i].data,
        fill: false,
        backgroundColor: colors[i],
        borderColor: colors[i],
        key: i
      };
      chartData.push(chartObject);
    }
    setChartData(chartData);
  }, [ filesData ]);

  useLayoutEffect(() =>{
    if(chartRef.current !== null){
      chartRef.current.chartInstance.config.options.scales.xAxes[0].type = axesType;
      chartRef.current.chartInstance.update();
    }
  }, [ axesType ]);

  useEffect(() => {
    if (props.title === 'Cole') {
      setreversed(true);
    }
  }, [props.title]);

  const toggleAxesType = () => {
    axesType === 'linear' ? setAxesType('logarithmic') : setAxesType('linear')
  }

  return (
    <Box sx={{ height: '100%'}}>
      {props.error && 
        <ErrorAlert
          error={props.error}
          handleClose={ () => props.errorClose() }
        />
      }
      <GenericCard
        title={props.title}
        action={toggleAxesType}
      >
        <div className="chart">
        {chartData.length>0 && 
        <Line
          // redraw
          type={"scatter"}
          ref={chartRef}
          data={{            
              datasets: chartData
            }}
            options={{
              plugins: {
                legend: {
                  position: 'bottom',
                },
              },
              scales: {
                xAxes: [{
                  type: axesType,
                  position: 'bottom'
                }],
                yAxes: [{
                  type: 'linear',
                  position: 'left',
                  ticks:{
                    reverse: reversed
                  }
                }]
              },
              maintanAspectRatio: false,
            }}
        />}
        </div>
        </GenericCard>
      </Box>
  );
};
export default LineChart;